import { enableProdMode, importProvidersFrom } from '@angular/core';

import { customTooltipDefaults, HttpLoaderFactory } from './app/app.module';
import { environment } from './environments/environment';
import { AppComponent } from './app/components/global/global.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularDraggableModule } from 'angular2-draggable';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSortModule } from '@angular/material/sort';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppRoutingModule } from './app/app.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { AuthInterceptor } from './app/services/interceptor.service';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpBackend, HttpClient } from '@angular/common/http';
import { Title, BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AuthGuard, SSOAuthGuard, LoginGuard, RoleGuard, FeatureGuard, SSORoleGuard } from './app/services/guard.service';
import { CookieService } from 'ngx-cookie-service';
import { HelperService, GlobalVariables } from './app/services/helper.service';
import { CrSyncService } from './app/services/crsync.service';
import { OnOnService } from './app/services/onon.service';
import { VideoServerService } from './app/services/videoserver.service';
import { ApiService } from './app/services/api.service';

import '@angular/compiler';
import { RouterModule } from '@angular/router';
import { PickUpAlertIcon } from 'app/pipes/pipes';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
      PickUpAlertIcon,
        importProvidersFrom(BrowserModule, InfiniteScrollModule, FormsModule, AppRoutingModule, TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpBackend]
            }
        }), MatExpansionModule, MatPaginatorModule, RouterModule, NgxBootstrapMultiselectModule , ColorPickerModule, MatSortModule, MatTooltipModule, MatAutocompleteModule, ReactiveFormsModule, AngularDraggableModule, DragDropModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatDatepickerModule, MatNativeDateModule, MatInputModule, MatListModule, MatStepperModule, MatButtonModule, MatIconModule, MatCheckboxModule, MatRadioModule, MatDialogModule, MatExpansionModule, NgxMaterialTimepickerModule, MatTabsModule, MatTableModule, MatMenuModule, MatSidenavModule, MatToolbarModule, MatMenuModule, MatTableModule, MatGridListModule, MatCardModule, MatTableModule, MatSnackBarModule),
        ApiService,
        VideoServerService,
        OnOnService,
        CrSyncService,
        HelperService,
        CookieService,
        AuthGuard,
        SSOAuthGuard,
        SSORoleGuard,
        LoginGuard,
        RoleGuard,
        FeatureGuard,
        Title,
        GlobalVariables,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipDefaults },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
});
