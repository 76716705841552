import { LocationModel } from './location.model';
import { Route } from './alarmroutes.model';
import { ReceiverTeam } from './receiver-team';

export class StaffModel {
  id: string;
  fullName: string;
  firstName: string;
  familyName: string;
  username: string;
  emaOnline: string;
  alarmRoutes: Route[];
  teams: ReceiverTeam[];
  locationId: string;
  language: string;
  gsmNumber: string;
  email: string;
  usingLevel: number;
  roleType: string;
  location: LocationModel;
  passwordChanged: string;
  locked: boolean;
  identityUsername: string;
}


export class IdentityUser {
  username: string;
  firstname: string;
  familyName: string;
  email: string;
}