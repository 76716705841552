import { ReportFilter, FilterOptions } from "app/services/helper.service";
import {
  IMultiSelectOption,
  IMultiSelectSettings,
  IMultiSelectTexts,
} from "ngx-bootstrap-multiselect";

// Setting templates and utility classes for ss-multiselect-dropdown button

/**
 * Gray colored button for selecting multiple items
 */
export const GRAY_MULTISELECT_DROPDOWN_SETTINGS: IMultiSelectSettings = {
  enableSearch: true,
  showCheckAll: false,
  showUncheckAll: true,
  closeOnSelect: false,
  checkedStyle: "checkboxes",
  buttonClasses: "btn graySSBtn fullWidth",
  containerClasses: "fullWidth",
  selectionLimit: 0,
  autoUnselect: false,
  loadViewDistance: 1,
  dynamicTitleMaxItems: 0,
  stopScrollPropagation: true,
  selectAddedValues: true,
  focusBack: false,
};

/**
 * Configurations for the statistics filter multiselect options
 */
export const STATISTICS_FILTER_SELECT_CONFIGS: ReportFilter = {
  settings: {
    enableSearch: true,
    showUncheckAll: true,
    dynamicTitleMaxItems: 0,
    checkedStyle: "fontawesome",
    containerClasses: "fullWidth",
    buttonClasses: "btn statisticsDropdownFilterBtn",
  },
  singleSelectSettings: {
    enableSearch: true,
    showUncheckAll: true,
    dynamicTitleMaxItems: 1,
    checkedStyle: "fontawesome",
    containerClasses: "fullWidth",
    buttonClasses: "btn statisticsDropdownFilterBtn",
    autoUnselect: true,
    selectionLimit: 1,
  },
  device: new FilterOptions(),
  location: new FilterOptions(),
  alertRoute: new FilterOptions(),
  basestation: new FilterOptions(),
  personnel: new FilterOptions(),
  deviceTypes: new FilterOptions(),
  activations: new FilterOptions(),
  eventTypes: new FilterOptions(),
};

/**
 * Multiselect settings with boostrap select-option dropdown like styling
 */
export const DROPDOWN_MULTISELECT_SETTINGS: IMultiSelectSettings = {
  enableSearch: true,
  showCheckAll: false,
  showUncheckAll: true,
  closeOnSelect: false,
  checkedStyle: "checkboxes",
  buttonClasses: "dropdownBtn dropdownBtn-sm fullWidth",
  containerClasses: "fullWidth",
  selectionLimit: 0,
  autoUnselect: false,
  loadViewDistance: 1,
  dynamicTitleMaxItems: 5,
  stopScrollPropagation: true,
  selectAddedValues: true,
  focusBack: false,
  maintainSelectionOrderInTitle: true,
};

export class MultiSelectOption {
  options: IMultiSelectOption[] = [];
  selected?: string[] = [];
  settings: IMultiSelectSettings = {};
  texts: IMultiSelectTexts = {};
}
