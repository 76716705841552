import { IGroupedOption } from "app/components/multiselect-dropdown/multiselect-dropdown.component";

export type Action = "Create" | "Modify" | "Delete" | "Authentication";
export type Area = "Staff" | "Portal" | "EMA" | "Digital Supervision";
export type ReportType = "UserActivityPortal";

export type ActivityTranslationCode =
  | "STAFF_ACCOUNT_MODIFIED"
  | "STAFF_ACCOUNT_CREATED"
  | "STAFF_ACCOUNT_DELETED"
  | "USER_LOGGED_IN"
  | "USER_LOGGED_OUT"
  | "USER_LOGIN_FAILED"
  | "CAMERA_GROUP_CREATED"
  | "CAMERA_GROUP_DELETED"
  | "CAMERA_GROUP_MODIFIED"
  | "SCHEDULED_VISIT_CREATED"
  | "SCHEDULED_VISIT_DELETED";

export const VISIBLE_DETAILS_FIELDS_COUNT = 10;

// Not in use at the moment
export const REPORT_TYPE_OPTIONS: { value: ReportType; name: string }[] = [
  { value: "UserActivityPortal", name: "PORTAL_USER_ACTIVITY" },
];

// Note: options needs to alphebetically ordered
export const AREA_OPTIONS: { value: Area; name: string }[] = [
  { value: "Digital Supervision", name: "DIGITAL_SUPERVISION" },
  { value: "EMA", name: "EMA" },
  { value: "Portal", name: "Everon Portal" },
  { value: "Staff", name: "STAFF" },
];

// Note: options needs to alphebetically ordered
export const ACTION_OPTIONS: { value: Action; name: string }[] = [
  { value: "Authentication", name: "AUTHENTICATION" },
  { value: "Create", name: "CREATED" },
  { value: "Delete", name: "DELETED" },
  { value: "Modify", name: "MODIFIED" },
];

export enum ActivityTargetClass {
  CameraGroup = "Camera group",
  ScheduledVisit = "Scheduled visit",
}

export class ActivityLogFilters {
  reportTypes: ReportType[] = [];
  users: string[] = [];
  areas: Area[] = [];
  actions: Action[] = [];
  search: string = "";
  pageIndex: number = 0;
  pageSize: number = 25;
}

export class ActivityLogFilterOptions {
  actions: { value: Action; name: string }[] = ACTION_OPTIONS;
  areas: { value: Area; name: string }[] = AREA_OPTIONS;
  reportTypes: { value: ReportType; name: string }[] = REPORT_TYPE_OPTIONS;
  users: IGroupedOption[] = [];
}

export interface IActivityLog {
  id: string;
  timestamp: string;
  companyId: string;
  staffId: string;
  staffName: string;
  area: Area;
  details: IActivityDetails;
  action: Action;
}

export interface IActivityDetails {
  // The translation code of the activity related action
  translationCode: string;
  // The name of the target entity e.g. staff name
  targetName: string;
  // The id of the target entity e.g. staff id
  targetId: string;
  // Class for which the target belongs to, e.g. Camera group, Sheduled visit
  targetClass?: ActivityTargetClass;
  // The role level of the target entity e.g. staff role level
  roleLevel?: number;
  // Changes made to the user account
  accountChanges?: IUserAccountChangeRecord;
  // Changes made to the camera group
  cameraGroupChanges?: ICameraGroupChangeRecord;
  // Activity details related to the scheduled visit
  scheduledVisitChanges?: IScheduledVisitChangeRecord;
}

export interface IValueChangePair<T> {
  // New value of the modified field
  newValue: T;
  // Old value of the modified field
  oldValue: T;
}

// Not in use
export interface IValueChangeSet<T> {
  added: T[];
  removed: T[];
}

export interface IUserAccountChangeRecord {
  roleLevel?: IValueChangePair<number>;
  lockRights?: IValueChangePair<number[]>;
  firstName?: IValueChangePair<string>;
  familyName?: IValueChangePair<string>;
  username?: IValueChangePair<string>;
  gsmNumber?: IValueChangePair<string>;
  email?: IValueChangePair<string>;
  locationId?: IValueChangePair<string>;
  language?: IValueChangePair<string>;
  identityUser?: IValueChangePair<string>;
  accountLocked?: boolean;
  accountUnlocked?: boolean;
  passwordChanged?: boolean;
  addedLocationRights?: string[];
  removedLocationRights?: string[];
  addedReceiverTeams?: string[];
  removedReceiverTeams?: string[];
  addedAlarmRoutes?: string[];
  removedAlarmRoutes?: string[];
}

export interface ICameraChangeInfo {
  // Camera identifier
  id: string;
  // Name of the camera
  name: string;
  // Motion detection enabled or disabled status of the camera
  motionDetection: boolean;
  // Anonymized visit enabled or disabled status of the camera
  anonymizedVisit: boolean;
  // View clear image enabled or disabled status of the camera
  clearImage: boolean;
}

export interface ICameraGroupChangeRecord {
  // Previous or new value of the modified camera group name
  groupName?: IValueChangePair<string>;
  // Previous or new value of the modified camera group address
  address?: IValueChangePair<string>;
  // Previous or new value of the modified camera group postal code
  postalCode?: IValueChangePair<string>;
  // Previous or new value of the modified camera group city
  city?: IValueChangePair<string>;
  // Previous or new value of the modified camera group location
  location?: IValueChangePair<string>;
  // Previous or new value of the modified camera group access mode as translation code
  accessModeTranslationCode?: IValueChangePair<string>;
  // Information about the cameras that have been added to the camera group
  addedCameras?: ICameraChangeInfo[];
  // Information about the cameras that have been modified in the camera group
  modifiedCameras?: ICameraChangeInfo[];
  // List of camera that have been removed from the camera group
  removedCameras?: string[];
  // Alert trigger devices that have been added to the camera group
  addedAlertDevices?: string[];
  // Removed alert trigger devices from the camera group
  removedAlertDevices?: string[];
  // Added receiver groups to the camera group
  addedReceiverGroups?: string[];
  // Removed receiver groups from the camera group
  removedReceiverGroups?: string[];
  // Previous or new value of the modified camera group visit time in minutes
  visitTimeMinutes?: IValueChangePair<number | null>;
  // Previous or new value of the modified camera group visit grace time in minutes
  graceTimeMinutes?: IValueChangePair<number | null>;
}

export interface IScheduledVisitChangeRecord {
  // List of weekdays as translation codes
  weekdayTranslationCodes: string[];
  // Start time of the scheduled visit
  startTime: string;
  // End time of the scheduled visit
  endTime: string;
  // Extended visit time in minutes
  extendedVisitTimeMinutes: number;
}

export type UserAccountChangeRecordField = keyof IUserAccountChangeRecord;
export type CameraGroupChangeRecordField = keyof ICameraGroupChangeRecord;
export type ScheduledVisitChangeRecordField = keyof IScheduledVisitChangeRecord;
