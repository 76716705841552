import { Directive } from "@angular/core";
import {
  NG_ASYNC_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ApiService } from "../services/api.service";

@Directive({
    selector: "[appUsernameValidatorAsync]",
    providers: [
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: UsernameValidatorDirective,
            multi: true,
        },
    ],
    standalone: true
})
export class UsernameValidatorDirective implements Validator {
  constructor(private api: ApiService) {}

  validate(ctrl: AbstractControl): Observable<ValidationErrors | null> {
    return this.api.checkUsername(ctrl.value).pipe(
      map((res) => {
        return res ? null : { invalidUsername: true };
      }),
      catchError(() => {
        return of({ invalidUsername: true });
      })
    );
  }
}
