/**
 * Form model for creating new camera groups
 */
export class NewCameraGroupForm {
  constructor(user: any) {
    this._serverId = user.serverId;
    this.CompanyId = this._serverId + user.customerId;
    this.timeZone = user.timezone;
  }
  // Avoid the underscore "_" naming convention for those private property members, which are going to be sent to the API
  private AccessMode: AccessMode | undefined = undefined;
  private LocationId: string = "";
  private readonly CompanyId: string;
  private readonly _serverId: string;
  readonly id: string;
  readonly type: string = "cameraGroup";
  readonly version: number = 1;
  readonly system: string = "production";
  readonly timeZone: string;

  userGroups: string[] = [];
  cameras: VideoServerCamera[] = [];
  displayName: string;
  address: string;
  postOffice: string;
  postCode: string;

  get accessMode(): AccessMode | undefined {
    return this.AccessMode;
  }

  set accessMode(mode: AccessMode) {
    if (Object.values(AccessMode).includes(mode as AccessMode)) {
      this.AccessMode = mode as AccessMode;
    } else {
      this.AccessMode = undefined;
    }
  }

  get locationId(): string {
    return this.LocationId.substring(8);
  }

  set locationId(id: string) {
    this.LocationId = id ? this._serverId + id : "";
  }

  get getCompanyId(): string {
    return this.CompanyId.substring(8);
  }

  /**
   * Validate form data
   * @returns true if valid, else false
   */
  isValid(): boolean {
    if (
      this.LocationId &&
      this.displayName &&
      this.cameras?.length > 0
    ) {
      return true;
    }
    return false;
  }
}

/**
 * Form model for modifying existing camera group
 */
export class CameraGroupEditForm {
  constructor(cameraGroupToEdit: ICameraGroup) {
    Object.assign(this, cameraGroupToEdit); // Deep copy the immediate members of an object
    this.cameras = require("lodash").cloneDeep(this.cameras); // Deep copy nested camera objects
    this._serverId = cameraGroupToEdit.companyId.substring(0, 8);
  }
  // Avoid the underscore "_" naming convention for those private property members, which are going to be sent to the API
  private AccessMode: AccessMode | undefined;
  private LocationId: string = "";
  private readonly CompanyId: string;
  private readonly _serverId: string = "";
  readonly type: string;
  readonly version: number;
  readonly system: string;
  readonly id: string;
  readonly timeZone: string;

  userGroups: string[] = [];
  cameras: VideoServerCamera[] = [];
  displayName: string;
  address: string;
  postOffice: string;
  postCode: string;

  get accessMode(): AccessMode | undefined {
    return this.AccessMode;
  }

  set accessMode(mode: AccessMode) {
    if (Object.values(AccessMode).includes(mode as AccessMode)) {
      this.AccessMode = mode as AccessMode;
    } else {
      this.AccessMode = undefined;
    }
  }

  get locationId(): string {
    return this.LocationId.substring(8);
  }

  set locationId(id: string) {
    this.LocationId = id ? this._serverId + id : "";
  }

  get getCompanyId(): string {
    return this.CompanyId.substring(8);
  }

  /**
   * Validate form data
   * @returns true if valid, else false
   */
  isValid(): boolean {
    if (
      this.LocationId &&
      this.displayName &&
      this.cameras?.length > 0
    ) {
      return true;
    }
    return false;
  }
}

export interface ICameraGroup {
  id: string;
  accessMode: AccessMode | undefined;
  cameras: VideoServerCamera[];
  userGroups: string[];
  companyId: string;
  locationId: string;
  address: string;
  postOffice: string;
  postCode: string;
  displayName: string;
  timeZone: string;
  type: string;
  version: number;
  system: string;
}

export class VideoServerCamera {
  readonly deviceId: string = undefined; // GUID
  discoveryName: string;
  displayName: string;
  anonymization: boolean;
  toggleAnonymization: boolean;
  motionDetection: {
    enabled: boolean;
    snapshot: boolean;
    videoclip: boolean;
  };
}

export class VisitOnAlertConfig implements IVisitConfiguration {
  readonly type: string = "alertsForCameraGroup";
  readonly version: number = 1;
  readonly system: string = "production";
  readonly id: string;

  alertSources: { deviceID: string }[] = [];
  options = { visitTimeMinutes: 10, visitGraceTimeMinutes: 0 };
}

export interface IVisitConfiguration {
  id: string;
  type: string;
  version: number;
  system: string;
  alertSources: { deviceID: string }[];
  options: { visitTimeMinutes: number; visitGraceTimeMinutes: number };
}

export enum AccessMode {
  Always = "always",
  VisitOnAlert = "visitOnAlert",
}

export enum VisitTimeOption {
  visitTimeMinutes,
  visitGraceTimeMinutes,
}
