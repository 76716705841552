import {
  ActivityLogFilterOptions,
  ActivityLogFilters,
  IActivityLog,
} from "app/models/activity-log.model";
import { Injectable, OnDestroy } from "@angular/core";
import { ApiService } from "./api.service";
import { HelperService } from "./helper.service";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, switchMap, tap } from "rxjs/operators";

const TOP_LEVEL_ICON = "fa-solid fa-user";
const LOCATION_ICON = "fa-solid fa-house";

/**
 * Service for activity log related operations and business logic
 */
@Injectable()
export class ActivityLogService implements OnDestroy {
  constructor(private api: ApiService, private helper: HelperService) {
    this.initActivityLogsFetch();
  }

  private activityLogsSubject = new BehaviorSubject<IActivityLog[]>([]);
  private filtersSubject = new BehaviorSubject<ActivityLogFilters>(new ActivityLogFilters());

  activityLogs$: Observable<IActivityLog[]>;
  activityLogsLoading: boolean = false;
  infiniteScrollDisabled: boolean = false;

  get filters(): ActivityLogFilters {
    return this.filtersSubject.value;
  }

  /**
   * Fetches the next batch of logs based on the current filters
   */
  getNextLogBatch(): void {
    this.filters.pageIndex++;
    this.fetchLogs(this.filters);
  }

  /**
   * Updates the filters and fetches the logs based on the new filters
   * @param newFilters The new filters to fetch the logs with
   */
  updateFilters(newFilters: ActivityLogFilters): void {
    newFilters.pageIndex = 0; // Reset the page index when the filters change
    this.activityLogsSubject.next([]); // Clear the logs when the filters change
    this.fetchLogs(newFilters);
  }

  /**
   * Gets the user filters from the backend and sets the user filter options
   * @param filterOptions The filter options to set the user options on
   */
  getUserFilters(filterOptions: ActivityLogFilterOptions): void {
    this.api.getUserFilters().subscribe((options) => {
      filterOptions.users = options;
      this.replaceCompanyLabel(filterOptions);
      this.fillUserOptionIcons(filterOptions);
    });
  }

  ngOnDestroy(): void {
    this.activityLogsSubject.complete();
    this.filtersSubject.complete();
  }

  // Helper methods below

  /**
   * Replaces the first option's label with the customer name. This is because the first option name is not set in the backend
   * and it requires lot of changes to set it there. So, we are replacing it here.
   * @param filterOptions The filter options to modify
   */
  private replaceCompanyLabel(filterOptions: ActivityLogFilterOptions): void {
    const customerInfo = this.helper.getCustomerInfo();

    // Replace the first label with the customer name, since the first item returned from backend is always the top level
    filterOptions.users[0].label = customerInfo?.name;
  }

  /**
   * Fill option icons for user filter
   * @param filterOptions The filter options to modify
   */
  private fillUserOptionIcons(filterOptions: ActivityLogFilterOptions): void {
    filterOptions.users.forEach((option) => (option.iconClass = LOCATION_ICON));
    filterOptions.users[0].iconClass = TOP_LEVEL_ICON;
  }

  /**
   * Fetches the logs based on the filters
   * @param filters The filters to fetch the logs with
   */
  private fetchLogs(filters: ActivityLogFilters) {
    this.filtersSubject.next(filters); // Update the filters behavior subject to fetch the logs from the backend
  }

  /**
   * initializing the activityLogs$ observable based on the current filters with switchMap for cancellation effect
   */
  private initActivityLogsFetch(): void {
    this.filtersSubject.pipe(
      tap(() => (this.activityLogsLoading = true)),
      switchMap((filters) =>
        this.api
          .getActivityLogs(filters)
          .pipe(tap(() => (this.activityLogsLoading = false)))
      ),
      // tap((logs) => console.log(logs)),
      tap((logs) => this.infiniteScrollDisabled = !logs || logs.length === 0), // Disable infinite scroll if there are no logs to fetch
      filter((logs) => logs !== null), // Select only non-null logs
    ).subscribe((logs) => this.activityLogsSubject.next([...this.activityLogsSubject.getValue(), ...logs]));

    this.activityLogs$ = this.activityLogsSubject.asObservable();
  }
}
