export class ReportRequestData {
  fromTimestamp: any;
  toTimestamp: any;
  groupBy: string;
  statsType: string;
  countLimits: {
    lowerLimit: number;
    upperLimit: number;
  };
  deviceFilter: string[];
  locationFilter: string[];
  alertRouteFilter: string[];
  basestationFilter: string[];
  personnelFilter: string[];
}

export enum TimeZone {
  'Europe/London' = 0,
  'Europe/Stockholm' = 1,
  'Europe/Helsinki' = 2,
}

export interface ReportPDFRequest {
  reportType: string;
  language: string;
  fromTimestamp: string;
  toTimestamp?: string;
  fromTimeOfDay: string;
  toTimeOfDay: string;
  emailList: string[];
  locationFilter?: string[];
  deviceFilter?: string[];
  alertRouteFilter?: string[];
  basestationFilter?: string[];
  personnelFilter?: string[];
  deviceTypeFilter?: string[];
  activationFilter?: string[];
  weekDayFilter?: number[];
  locationTimeZone: number;
}

export class ReportSummaryRequest {
  fromTimestamp: any;
  toTimestamp: any;
  devices: any;
}

export class EMAACStatisticsRequest {
  fromTimestamp: any;
  toTimestamp: any;
  alertRouteFilter: string[];
}

export interface DeviceAlert {
  deviceName: string;
  deviceType: string;
  deviceId: string;
  locationName: string;
  alarms: number;
  activationGroup?: number;
  activationNode?: number;
  startDate: Date;
  endDate: Date;
}

export interface AlarmTypes {
  total: number;
  basestationButton: number;
  alarmButton: number;
  fallAlarm: number;
  movement: number;
  doorAlarm: number;
  batteryState: number;
}
