import {
  Component,
  OnInit,
  Input,
  HostListener,
  OnDestroy,
  ChangeDetectionStrategy,
} from "@angular/core";
import { Chart, ChartData } from "chart.js";
import { Subscription, fromEvent } from "rxjs";
import { filter } from "rxjs/operators";
import { EMAAC_ALERTS_PIE_CHART_CONFIGS } from "app/shared/chart-configs.shared";
import { IEmaacAlertStatistics } from "app/models/statistics.model";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { emaacAlertsCustomTooltipHandler } from "app/shared/chart-tooltips.shared";

@Component({
  selector: "emaac-alerts",
  templateUrl: "./emaac-alerts.component.html",
  styleUrls: ["./emaac-alerts.component.css"],
  standalone: true,
  imports: [TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmaacAlertsComponent implements OnInit, OnDestroy {
  constructor(private translate: TranslateService) {}

  @Input() emaacAlertStats: IEmaacAlertStatistics;

  chart: Chart<"pie">;
  labels: string[];

  mouseRightClickSubscription: Subscription;

  // Prevent opening browser's mouse right click dropdown menu when clicked
  @HostListener("contextmenu", ["$event"])
  onContextMenu(event: MouseEvent) {
    event.preventDefault();
  }

  resetZoom() {
    this.chart.resetZoom();
  }

  drawEmaacAlertsChart(): void {
    const emaAlerts = this.emaacAlertStats.emaAlerts;
    const finishedAlerts = this.emaacAlertStats.finishedAlerts;

    // Parse chart data
    const chartData: ChartData<"pie"> = {
      labels: this.labels,
      datasets: [
        {
          data: [emaAlerts, finishedAlerts],
          backgroundColor: [
            "rgb(255, 118, 74)", // Orange
            "rgb(44, 63, 104)", // Dark blue
          ],
        },
      ],
    };
    EMAAC_ALERTS_PIE_CHART_CONFIGS.data = chartData;
    // Add custom tooltip to the chart
    EMAAC_ALERTS_PIE_CHART_CONFIGS.options.plugins.tooltip.external =
      emaacAlertsCustomTooltipHandler;

    // Create chart
    this.chart = new Chart("emaacAlertsChart", EMAAC_ALERTS_PIE_CHART_CONFIGS);
  }

  ngOnInit(): void {
    this.translate
      .get(["SENT_TO_EMA", "FINISHED_BY_ALARM_CENTER"])
      .subscribe((t) => {
        this.labels = [t.SENT_TO_EMA, t.FINISHED_BY_ALARM_CENTER];
        this.drawEmaacAlertsChart();
      });

    // Init listening mouse right click event
    const mouseRightClick$ = fromEvent(document, "mousedown").pipe(
      filter((event: MouseEvent) => event.button === 2)
    );

    this.mouseRightClickSubscription = mouseRightClick$.subscribe(() => {
      this.resetZoom();
    });
  }

  ngOnDestroy(): void {
    this.mouseRightClickSubscription.unsubscribe();
  }
}
