import { Component, OnInit } from '@angular/core';
import { ROLE_TYPES, LOCK_TYPES } from 'app/models/unloc.model';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-unloc',
    templateUrl: './unloc.component.html',
    styleUrls: ['./unloc.component.css'],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule]
})
export class UnlocComponent implements OnInit {

  // Mock data:
  roleTypes = ROLE_TYPES;
  lockTypes = LOCK_TYPES;

  constructor() { }

  ngOnInit(): void {
  }

}
