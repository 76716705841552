<div class="card py-3 px-4">
    <h4 class="mainDarkBlueText">
        {{ "RESPONSE_TIME" | translate }}
    </h4>

    <div class="d-flex pt-3">
        <div>
            <label class="mainDarkBlueText form-control"
                ><input
                    class="mr-1"
                    type="radio"
                    name="responseTimeOption"
                    [(ngModel)]="selectedOption"
                    value="average"
                    checked
                />{{ "MEAN_TEXT" | translate }}</label
            >
        </div>

        <div class="ml-3">
            <label class="mainDarkBlueText form-control"
                ><input
                    class="mr-1"
                    type="radio"
                    name="responseTimeOption"
                    id="median"
                    [(ngModel)]="selectedOption"
                    value="median"
                />{{ "MEDIAN" | translate }}</label
            >
        </div>
    </div>

    <h2 class="mainDarkBlueText">
        {{
            (selectedOption === "average"
                ? resTimeStats.avgResTimeSeconds
                : resTimeStats.medianResTimeSeconds
            ) | formatSeconds
        }}
    </h2>
</div>
