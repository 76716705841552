import { Injectable } from "@angular/core";
import { AsyncValidator, AbstractControl, ValidationErrors } from "@angular/forms";
import { ApiService } from "./api.service";
import { Observable, of } from 'rxjs';
import { map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class ValidateUsernameAsync implements AsyncValidator {
    constructor(private api: ApiService) { }

    validate(
        ctrl: AbstractControl
    ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        if (ctrl.dirty) {
            return this.api.checkUsername(ctrl.value).pipe(
                map((res: Response) => {
                    return res ? null : { uniqueUsername: true };
                }))
        } else {
            return of(null);
        }
    }
}