import { AlertsByAlarmReasonComponent } from "./../../alerts-by-alarm-reason/alerts-by-alarm-reason.component";
import { Subscription, combineLatest } from "rxjs";
import { StatisticsService } from "app/services/statistics.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { IEmaacStatistics } from "app/models/statistics.model";
import { AlarmService } from "app/services/alarm.service";
import { IAlarmReasonTranslations } from "app/models/alarm-reasons.model";
import { UiLang } from "app/services/helper.service";
import { NgIf } from "@angular/common";
import { CareRecipientCounterComponent } from "../../care-recipient-counter/care-recipient-counter.component";
import { EmaacAlertsComponent } from "../../emaac-alerts/emaac-alerts.component";
import { ResponseTimeStatisticsComponent } from "../../response-time-statistics/response-time-statistics.component";
import { AlertsPerDayComponent } from "../../alerts-per-day/alerts-per-day.component";
import { AlertsPerHourComponent } from "../../alerts-per-hour/alerts-per-hour.component";
import { AlertResponseTimesComponent } from "../../alert-response-times/alert-response-times.component";

@Component({
  selector: "alarm-center-tab",
  templateUrl: "./alarm-center-tab.component.html",
  styleUrls: ["./alarm-center-tab.component.css"],
  standalone: true,
  imports: [
    NgIf,
    CareRecipientCounterComponent,
    EmaacAlertsComponent,
    ResponseTimeStatisticsComponent,
    AlertsByAlarmReasonComponent,
    AlertsPerDayComponent,
    AlertsPerHourComponent,
    AlertResponseTimesComponent,
  ],
})
export class AlarmCenterTabComponent implements OnInit, OnDestroy {
  constructor(
    public statistics: StatisticsService,
    private alarmService: AlarmService
  ) {}

  emaacStatisticsData: IEmaacStatistics;
  alarmReasonTranslations: IAlarmReasonTranslations;
  subscription: Subscription;

  ngOnInit() {
    const language = localStorage.getItem("language") as UiLang;

    const emaacStatistics$ = this.statistics.emaacStatistics$;
    const translations$ =
      this.alarmService.getAlarmReasonTranslations(language);

    this.subscription = combineLatest([
      emaacStatistics$,
      translations$,
    ]).subscribe(([emaacStatistics, translations]) => {
      if (!this.alarmReasonTranslations && translations) {
        this.alarmReasonTranslations = translations;
      }
      if (emaacStatistics) {
        this.emaacStatisticsData = emaacStatistics;
        this.statistics.alarmCenterTabDataLoaded = true;
      }
    });
    this.statistics.fetchEmaacStatistics$.next();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
