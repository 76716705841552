import { Injectable } from "@angular/core";
import * as moment from "moment-timezone";
import { ApiService } from "./api.service";
import { EMPTY, Observable } from "rxjs";
import { CameraEvent, LockEvent } from "app/models/report.model";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor(
    private api: ApiService
  ) {}

  setReportFilters(
    data: any,
    advancedFilter: any,
    customDeviceOption: any,
    customDeviceQuery: any,
    customBasestationOption: any,
    customBasestationQuery: any,
    weekDayFilter: any,
    filterStartDay: any,
    filterEndDay: any,
    filterStartTime: any,
    filterEndTime: any
    ): any {

    let alertsData = data;
    if (advancedFilter.device.selectedList) {
      alertsData.deviceFilter = advancedFilter.device.selectedList.slice();
      alertsData.deviceFilter = alertsData.deviceFilter.filter(
        (deviceId: string) => deviceId !== customDeviceOption.id
      );
      if (customDeviceQuery) {
        alertsData.deviceFilter.push(customDeviceQuery);
      }
    }

    if (advancedFilter.location.selectedList) {
      alertsData.locationFilter = advancedFilter.location.selectedList;
    }

    if (advancedFilter.alertRoute.selectedList) {
      alertsData.alertRouteFilter = advancedFilter.alertRoute.selectedList;
    }

    if (advancedFilter.deviceTypes.selectedList) {
      alertsData.deviceTypeFilter =
        advancedFilter.deviceTypes.selectedList;
    }

    if (advancedFilter.basestation.selectedList) {
      alertsData.basestationFilter =
        advancedFilter.basestation.selectedList.slice();
      alertsData.basestationFilter = alertsData.basestationFilter.filter(
        (baseStationId: string) =>
          baseStationId !== customBasestationOption.id
      );
      if (customBasestationQuery) {
        alertsData.basestationFilter.push(customBasestationQuery);
      }
    }

    if (advancedFilter.personnel.selectedList) {
      alertsData.personnelFilter = advancedFilter.personnel.selectedList;
    }

    if (advancedFilter.activations.selectedList) {
      alertsData.activationFilter =
        advancedFilter.activations.selectedList;
    }

    if (advancedFilter.alarmReasons.selectedList) {
      alertsData.alarmReasonFilter =
        advancedFilter.alarmReasons.selectedList;
    }

    if (weekDayFilter) {
      alertsData.weekDayFilter = weekDayFilter;
    }

    alertsData.fromTimestamp = moment(new Date(filterStartDay)).startOf("d").utc();
    alertsData.toTimestamp = moment(new Date(filterEndDay)).endOf("d").utc();

    if (filterStartTime !== "00:00" || filterEndTime !== "23:59") {
      alertsData.fromTimeOfDay = moment(filterStartTime, "HH:mm")
        .utc()
        .format("HH:mm")
        .toString();
      alertsData.toTimeOfDay = moment(filterEndTime, "HH:mm")
        .utc()
        .format("HH:mm")
        .toString();
    }

    return alertsData;
  }

  /**
   * Check if invalid camera visit filters are set
   * @returns true if valid, false if invalid
   */
  isCameraVisitFiltersValid(advancedFilter: any): boolean {
    const invalidFilterSelectCount: number = [
      // Add Empty array if object is falsy to calculating sum of the invalid selected filters
      (advancedFilter.alertRoute.selectedList ?? []).length,
      (advancedFilter.basestation.selectedList ?? []).length,
      (advancedFilter.activations.selectedList ?? []).length,
    ].reduce((a, b) => a + b, 0);

    // Check if selected device filter list is valid
    const validDevTypeFilters
      = this.isCameraVisitDeviceTypeFiltersValid(advancedFilter.deviceTypes.selectedList);

    if (invalidFilterSelectCount > 0 || !validDevTypeFilters) {
      return false;
    }
    return true;
  }

  /**
   * Check device type filters validity for the camera visits
   * @param selectedDeviceTypes Selected device type filters
   * @returns True if valid, else false
   */
  isCameraVisitDeviceTypeFiltersValid(selectedDevTypes: string[] | null): boolean {
    const validDevTypes = ["34-1"];
    let valid = true;
    // Check if selected list contains invalid device types
    if (selectedDevTypes && selectedDevTypes.length > 0) {
      valid = selectedDevTypes.some((type) => validDevTypes.includes(type));
    }
    return valid;
  }

  getCameraVisit(
    page: number,
    pageSize: number,
    advancedFilter: any,
    customDeviceOption: any,
    customDeviceQuery: any,
    customBasestationOption: any,
    customBasestationQuery: any,
    weekDayFilter: any,
    filterStartDay: any,
    filterEndDay: any,
    filterStartTime: any,
    filterEndTime: any,
    cameraVisitsEnabled: any): Observable<CameraEvent[]> {

    if (this.isCameraVisitFiltersValid(advancedFilter) && cameraVisitsEnabled) {
      const paging = {
        paging: {
          alertsPerPage: pageSize,
          pageNumber: page,
        }
      }
      const filters = this.setReportFilters(
        paging,
        advancedFilter,
        customDeviceOption,
        customDeviceQuery,
        customBasestationOption,
        customBasestationQuery,
        weekDayFilter,
        filterStartDay,
        filterEndDay,
        filterStartTime,
        filterEndTime);  //this.setReportFilters(paging);
      return this.api.getCameraVisitHistory(filters);
    }
    return EMPTY;
  }

  getLockingEvents(
    page: number,
    pageSize: number,
    advancedFilter: any,
    customDeviceOption: any,
    customDeviceQuery: any,
    customBasestationOption: any,
    customBasestationQuery: any,
    weekDayFilter: any,
    filterStartDay: any,
    filterEndDay: any,
    filterStartTime: any,
    filterEndTime: any): Observable<LockEvent[]> {
    const paging = {
      paging: {
        alertsPerPage: pageSize,
        pageNumber: page,
      }
    }
    const filters = this.setReportFilters(
      paging,
      advancedFilter,
      customDeviceOption,
      customDeviceQuery,
      customBasestationOption,
      customBasestationQuery,
      weekDayFilter,
      filterStartDay,
      filterEndDay,
      filterStartTime,
      filterEndTime);  //this.setReportFilters(paging);
    return this.api.getLockingEventHistory(filters);
  }
}
