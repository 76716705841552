import { Pipe, PipeTransform } from '@angular/core';
import { DeviceCompanyGroup } from 'app/models/device.model';
import { DeviceStatus } from 'app/models/devicestatus.model';

@Pipe({
  name: 'resolveDeviceStatus',
  standalone: true,
})
export class DeviceStatusResolverPipe implements PipeTransform {

  transform(device: DeviceCompanyGroup): DeviceStatus {
    // If the device is not assigned to a company or location, is not in stock, is not lost nor in service, the status remains null
    let status: DeviceStatus = "noStatus";

    status = this.deviceIsLostOrInService(device);

    // If the status is not 'lost' or 'service', then check if the device is assigned to a company or location
    if (status == "noStatus") {

      if ((device.companyId !== "" && device.companyId !== undefined) ||
        (device.locationId !== "" && device.locationId !== undefined)) {
        // If the device is assigned to a company or location, the online/battery status has to be checked
        const statusProperty = this.getFirstTruthyStatusProperty(device);
        status = this.getStatusFromProperty(statusProperty);
      } else {
        // if there is no location attached, the inventory status has to be checked
        status = device.statusInEveronStock ? "everonInventory" : "inventory";
      }
    }
    return status;
  }

  private deviceIsLostOrInService(device: DeviceCompanyGroup): DeviceStatus {
    let status: DeviceStatus = "noStatus";
    if (device.statusLost) {
      status = "lost";
    } else if (device.statusInService) {
      status = "service";
    }
    return status;
  }

  private getFirstTruthyStatusProperty(device: DeviceCompanyGroup): string {
    const statusProperties = ["statusOnline", "statusOfflineUnder24h", "statusOfflineOver24h", "statusLowBattery"];
    for (const property of statusProperties) {
      if (device[property]) {
        return property;
      }
    }
    return "noStatus";
  }

  private getStatusFromProperty(statusProperty: string): DeviceStatus {
    switch (statusProperty) {
      case "statusOnline": return "online";
      case "statusOfflineUnder24h": return "offlineUnder24h";
      case "statusOfflineOver24h": return "offline";
      case "statusLowBattery": return "lowBattery";
      default: return "noStatus";
    }
  }
}
