import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { ApiService } from "app/services/api.service";
import { BehaviorSubject } from "rxjs";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgxBootstrapMultiselectModule } from "ngx-bootstrap-multiselect";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Device } from "app/models/device.model";
import { CameraNamePipe } from "app/pipes/pipes";
import {
  DeviceNamePipe,
  ReceiverTeamNamePipe,
} from "../camera-configuration-pipes";
import {
  CameraGroupEditForm,
  ICameraGroup,
  IVisitConfiguration,
  VisitOnAlertConfig,
  VisitTimeOption,
  AccessMode,
  VideoServerCamera,
} from "app/models/camera.model";
import { SelectCamerasComponent } from "../select-cameras/select-cameras.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { IGroupedOption, IOption, MultiselectDropdownComponent } from "app/components/multiselect-dropdown/multiselect-dropdown.component";
import { ICamerasByGroup } from "../camera-configuration.component";

interface ISelectedLocation { id: string, name: string };

@Component({
  selector: "camera-group-edit",
  templateUrl: "./camera-group-edit.component.html",
  styleUrls: ["../camera-configuration.component.css"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    NgxBootstrapMultiselectModule,
    MatSlideToggleModule,
    MatOptionModule,
    MatSelectModule,
    MatFormFieldModule,
    DeviceNamePipe,
    ReceiverTeamNamePipe,
    CameraNamePipe,
    SelectCamerasComponent,
    MatTooltipModule,
    MultiselectDropdownComponent,
  ],
})
export class CameraGroupEditComponent implements OnInit {
  constructor(private api: ApiService, private modalService: NgbModal) { }

  @Input() originalCameraGroup: ICameraGroup;
  @Input() originalVisitConfig: IVisitConfiguration;

  @Input() showForm: BehaviorSubject<boolean>;

  // Select option data variables
  @Input() alertDeviceSelect: IOption[] = [];
  @Input() receiverTeamSelect: IGroupedOption[] = [];
  @Input() locationSelect: { id: string; name: string }[] = [];
  @Input() cameraList: Device[] = [];
  @Input() assignedCameras: ICamerasByGroup[] = [];

  @Output() cameraGroupChanged = new EventEmitter<string>();

  cameraGroupEditForm: CameraGroupEditForm;
  visitConfigEditForm: VisitOnAlertConfig;

  showCameraExistsErrorMsg: boolean = false;
  showSubmitErrorMsg: boolean = false;
  selectedAlertDevices: string[] = [];

  confirmDelete: boolean = false;
  deletingCameraGroup: boolean = false;
  deleteDialogErrorMsg: string = "";

  camerasOfLocation: IGroupedOption[];
  cameraSelectDisabled = new BehaviorSubject<boolean>(true);
  selectedLocation: ISelectedLocation;

  submit(): void {
    const updatedVisitConfiguration: IVisitConfiguration =
      this.cameraGroupEditForm.accessMode === AccessMode.VisitOnAlert
        ? this.visitConfigEditForm
        : null;

    this.showSubmitErrorMsg = false;

    // Collect the alert devices used in old and new visit configurations and send them to the backend for activity logging, since it's requires less code to do it here.
    const alertDevices: Record<string, string> = this.alertDeviceSelect
      .filter(obj => {
        const array1 = this.originalVisitConfig?.alertSources ?? [];
        const array2 = updatedVisitConfiguration?.alertSources ?? [];
        return [...array1, ...array2].some(device => device.deviceID === obj.value)
      })
      .reduce((acc, obj) => {
        acc[obj.value] = obj.name;
        return acc;
      }, {} as Record<string, string>);

    this.api
      .updateCameraGroup({
        cameraGroupToUpdate: this.cameraGroupEditForm,
        visitConfigToUpdate: updatedVisitConfiguration,
        oldCameraGroupData: this.originalCameraGroup,
        oldVisitConfigData: updatedVisitConfiguration ? this.originalVisitConfig : null,
        alertDevices: alertDevices,
      })
      .subscribe(
        () => {
          this.cameraGroupChanged.emit("CAMERA_GROUP_UPDATED_SUCCESS");
          this.showForm.next(false);
        },
        (error) => {
          console.log(error);
          this.showSubmitErrorMsg = true;
        }
      );
  }

  openDeleteDialog(dialogEl: any): void {
    this.confirmDelete = true;

    this.modalService
      .open(dialogEl, {
        centered: true,
      })
      .result.then(
        () => {
          this.confirmDelete = false; // Set delete confirmation to false if dialog is closed or promise is rejected
          this.deletingCameraGroup = false;
        },
        () => {
          this.confirmDelete = false;
          this.deletingCameraGroup = false;
        }
      );
  }

  deleteCameraGroup(): void {
    if (this.confirmDelete) {
      const cameraGroupId = this.originalCameraGroup.id;
      const cameraGroupName = this.originalCameraGroup.displayName;
      const visitConfigId = this.originalVisitConfig
        ? this.originalVisitConfig.id
        : null;

      this.deleteDialogErrorMsg = "";
      this.deletingCameraGroup = true;

      this.api.deleteCameraGroup(cameraGroupId, visitConfigId, cameraGroupName).subscribe(
        (deleted) => {
          if (deleted) {
            this.modalService.dismissAll();
            this.cameraGroupChanged.emit("CAMERA_GROUP_DELETE_SUCCESS");
            this.showForm.next(false);
          } else {
            this.deletingCameraGroup = false;
            this.deleteDialogErrorMsg = "ERROR";
          }
        },
        (error) => {
          console.log(error);
          this.deletingCameraGroup = false;
          this.deleteDialogErrorMsg = "ERROR";
        }
      );
    }
  }

  onAccessModeChange(): void {
    const accessMode = this.cameraGroupEditForm.accessMode;

    if (accessMode === AccessMode.VisitOnAlert && !this.originalVisitConfig) {
      this.visitConfigEditForm = new VisitOnAlertConfig();
    }
  }

  compareNames(a: any, b: any): number {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
  }

  disableReceiverTeamLocationSelects(locations: IGroupedOption[]): IGroupedOption[] {
    const customerId = JSON.parse(localStorage.getItem("customerInfo")).customerId;

    // All receiver teams should be selectable, if selected location is a customer (top-level)
    if (this.cameraGroupEditForm.locationId === customerId) {
      locations.forEach(location => location.disabled = false);
    }
    else {

      for (let location of locations) {
        // If the location is not a customer, receiver groups from selected location and top-level location should be available
        if (
          location.id === this.cameraGroupEditForm.locationId ||
          location.id === customerId
        ) {
          location.disabled = false;
        } else {
          location.disabled = true;
        }
      }
    }

    return locations;
  }

  onLocationSelectChange(): void {
    let location: ISelectedLocation | string = this.selectedLocation;

    // Empty the selected teams and cameras when selected location is changed
    this.cameraGroupEditForm.userGroups = [];
    this.cameraGroupEditForm.cameras = [];
    this.cameraGroupEditForm.locationId = location.id;

    // Select the cameras for this location
    this.camerasOfLocation = [...this.selectCamerasOfThisLocation(location.id, this.camerasOfLocation)];
    this.receiverTeamSelect = [...this.disableReceiverTeamLocationSelects(this.receiverTeamSelect)];
  }

  parseSelectedDevices(event: string[]): void {
    const array: { deviceID: string }[] = [];

    for (let deviceId of event) {
      array.push({ deviceID: deviceId });
    }
    this.visitConfigEditForm.alertSources = array;
  }

  onCameraSelectChange(cameras: VideoServerCamera[]): void {
    this.cameraGroupEditForm.cameras = cameras;
  }

  setSelectedAlertDevices(deviceIds: { deviceID: string }[]): void {
    for (const obj of deviceIds) {
      this.selectedAlertDevices.push(obj.deviceID);
    }
  }

  setCount(count: number, option: VisitTimeOption): void {
    switch (option) {
      case VisitTimeOption.visitTimeMinutes:
        this.visitConfigEditForm.options.visitTimeMinutes =
          this.visitConfigEditForm.options.visitTimeMinutes + count >= 0
            ? count
            : 0;
        break;
      case VisitTimeOption.visitGraceTimeMinutes:
        this.visitConfigEditForm.options.visitGraceTimeMinutes =
          this.visitConfigEditForm.options.visitGraceTimeMinutes + count >= 0
            ? count
            : 0;
        break;
      default:
        break;
    }
  }

  validateVisitTimeInput(value: string, option: VisitTimeOption): void {
    // Check if the input is a number
    const isNumber = !isNaN(parseInt(value));
    switch (option) {
      case VisitTimeOption.visitTimeMinutes:
        if (!isNumber) this.visitConfigEditForm.options.visitTimeMinutes = 0;
        break;
      case VisitTimeOption.visitGraceTimeMinutes:
        if (!isNumber)
          this.visitConfigEditForm.options.visitGraceTimeMinutes = 0;
        break;
      default:
        break;
    }
  }

  initCameraSelect(): void {
    this.camerasOfLocation = [];

    for (const location of this.locationSelect) {
      const option: IGroupedOption = {
        label: location.name,
        id: location.id,
        disabled: true,
        options: this.cameraList
          .filter((camera) => camera.locationId === location.id)
          .map((camera) => {
            return {
              name: `${camera.name} (${camera.id})`,
              value: camera.id,
              disabled: false,
            }
          })
      }
      this.camerasOfLocation.push(option);
    }
    this.camerasOfLocation = this.camerasOfLocation.filter(group => group.options.length > 0);
    this.camerasOfLocation = [...this.selectCamerasOfThisLocation(this.cameraGroupEditForm.locationId, this.camerasOfLocation)];
  }

  selectCamerasOfThisLocation(id: string, cameras: IGroupedOption[]): IGroupedOption[] {
    for (const location of cameras) {
      id.startsWith("C02") || location.id === id
        ? location.disabled = false
        : location.disabled = true;

      this.shouldDisableCameras(location.options);
    }

    return cameras;
  }

  shouldDisableCameras(options: IOption[]): void {
    options.forEach(option => option.disabled = this.isCameraDisabled(option.value));
  }

  isCameraDisabled(cameraId: string): boolean {
    // Check if the camera is already assigned to another group
    return this.assignedCameras.some(group => group.groupId !== this.cameraGroupEditForm.id && group.cameraIds.includes(cameraId));
  }

  ngOnInit() {
    // Copy original data
    this.cameraGroupEditForm = new CameraGroupEditForm(
      this.originalCameraGroup
    );

    // set an initial option in the location select field
    this.selectedLocation = this.locationSelect.
      find(location => location.id === this.cameraGroupEditForm.locationId);

    // Initialize the camera selection
    this.initCameraSelect();

    // Visit original visit configuration data if exists
    if (this.originalVisitConfig) {
      this.visitConfigEditForm = require("lodash").cloneDeep(
        this.originalVisitConfig
      );
      this.setSelectedAlertDevices(this.visitConfigEditForm.alertSources);
    }
  }
}
