export const UnlocDoorTypes: any[] = [
  { value: "1", name: "LOCKED_DOOR_TYPE_ENTRY", requirePermission: false },
  { value: "10", name: "LOCKED_DOOR_TYPE_APARTMENT", requirePermission: false },
  { value: "12", name: "LOCKED_DOOR_TYPE_MC_CAT1", requirePermission: true },
  { value: "13", name: "LOCKED_DOOR_TYPE_MC_CAT2", requirePermission: true },
  { value: "14", name: "LOCKED_DOOR_TYPE_MC_CAT3", requirePermission: true },
  { value: "4", name: "LOCKED_DOOR_TYPE_CORRIDOR", requirePermission: false },
  { value: "9", name: "LOCKED_DOOR_TYPE_LUNCHROOM", requirePermission: false },
];

export const ROLE_TYPES: any[] = [
  { value: 1, name: "Administrative staff" },
  { value: 2, name: "Nurse - General" },
  { value: 3, name: "Nurse - Strong drugs" },
  { value: 4, name: "Nurse - Very strong drugs" },
];

export const LOCK_TYPES: any[] = [
  { value: 1, name: "Entry door" },
  { value: 2, name: "Apartment door" },
  { value: 3, name: "Medicine cabinet" },
  { value: 4, name: "Medicine cabinet - Strong drugs" },
  { value: 5, name: "Medicine cabinet - Very strong drugs" },
];

export const WEEKDAYS: any[] = [
  { value: 1, name: "Mon" },
  { value: 2, name: "Tue" },
  { value: 3, name: "Wed" },
  { value: 4, name: "Thu" },
  { value: 5, name: "Fri" },
  { value: 6, name: "Sat" },
  { value: 7, name: "Sun" },
];

export const LOCK_TYPE_LANG_CODES = [
  { id: 1, code: "LOCKED_DOOR_TYPE_ENTRY" },
  { id: 10, code: "LOCKED_DOOR_TYPE_APARTMENT" },
  { id: 12, code: "MEDICINE_CABINET" },
  { id: 13, code: "MEDICINE_CABINET" },
  { id: 14, code: "MEDICINE_CABINET" },
  { id: 4, code: "LOCKED_DOOR_TYPE_CORRIDOR" },
  { id: 9, code: "LOCKED_DOOR_TYPE_LUNCHROOM" },
];

export const LOCK_CATEGORY_LANG_CODES = [
  { id: 1, code: "" },
  { id: 10, code: "" },
  { id: 12, code: "CATEGORY1" },
  { id: 13, code: "CATEGORY2" },
  { id: 14, code: "CATEGORY3" },
  { id: 4, code: "" },
  { id: 9, code: "" },
];
