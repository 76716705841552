import { Component, Input, ChangeDetectionStrategy } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "care-recipient-counter",
  templateUrl: "./care-recipient-counter.component.html",
  styleUrls: ["./care-recipient-counter.component.css"],
  standalone: true,
  imports: [TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareRecipientCounterComponent {
  constructor() {}

  @Input() careRecipientCount: number;
}
