<div class="card">
    <div
        class="info"
        [ngClass]="{ 'pb-2': !careRecipientStats.card.showAlerts }"
    >
        <div class="float-right">
            <button
                #dropdownBtn
                class="selectDropdownBtn"
                [name]="careRecipientStats.card.id"
                (click)="showCardSettings = true"
            >
                <i class="fa-solid fa-ellipsis"></i>
            </button>
            <div
                *ngIf="showCardSettings"
                #settingsDropdown
                class="customDropdown"
            >
                <div class="dropDownOption" (click)="openEditCardDialog()">
                    {{ "EDIT_CARD" | translate }}
                </div>
                <div
                    class="dropDownOption deleteOption"
                    (click)="openDeleteCardDialog(confirmDeleteDialog)"
                >
                    <i class="fa-solid fa-trash pr-1" style="color: #d31d2b"></i
                    >{{ "DELETE_CARD" | translate }}
                </div>
            </div>
        </div>

        <h4 class="name font-weight-normal">
            {{ careRecipientStats.card.name }}
        </h4>

        <div
            *ngIf="careRecipientStats.card.showAlerts"
            class="d-flex align-items-baseline"
        >
            <h2 class="alertCounter">
                {{ careRecipientStats.data.totalAlerts }}
            </h2>
            <h5 class="font-weight-normal ml-2">{{ "ALERTS" | translate }}</h5>
        </div>
    </div>

    <div class="statistics">
        <ng-container *ngIf="careRecipientStats.card.showAvgWaitTime">
            <div class="d-flex">
                <h5 class="font-weight-normal">
                    {{ "AVG_WAITING_TIME" | translate }}
                </h5>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <h5 class="time">
                    {{
                        careRecipientStats.data.avgWaitTimeSeconds
                            | formatSeconds
                    }}
                </h5>
            </div>

            <div class="horizontalBar" [ngStyle]="avgWaitTimeBarStyles"></div>
        </ng-container>

        <ng-container *ngIf="careRecipientStats.card.showAvgCareTime">
            <div class="d-flex mt-3">
                <h5 class="font-weight-normal">
                    {{ "AVG_CARE_TIME" | translate }}
                </h5>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <h5 class="time">
                    {{
                        careRecipientStats.data.avgCareTimeSeconds
                            | formatSeconds
                    }}
                </h5>
            </div>

            <div class="horizontalBar" [ngStyle]="avgCareTimeBarStyles"></div>
        </ng-container>

        <div [hidden]="!careRecipientStats.card.showAlertReasons">
            <h5 class="font-weight-normal mt-3">
                {{ "MOST_COMMON_ALERT_REASONS" | translate }}
            </h5>

            <div #stackedAlarmBar class="alarmReasonBar"></div>

            <ol class="mt-2 p-0" style="list-style-type: none">
                <li
                    *ngFor="let label of alarmReasonLabels"
                    class="alarmInfoText"
                >
                    {{ label }}
                </li>
                <li *ngIf="alarmReasonLabels.length === 0">
                    {{ "NO_RESULTS" | translate }}
                </li>
            </ol>
        </div>
    </div>

    <div class="noteBlock">
        <ng-container *ngIf="noteErrorMsg">
            <div class="alert alert-danger noteErrorMsg" role="alert">
                {{ noteErrorMsg | translate }}
            </div>
        </ng-container>

        <div>
            <p
                class="addNote"
                *ngIf="!showNoteForm"
                (click)="showNoteForm = true"
            >
                + {{ "ADD_A_NOTE" | translate }}
            </p>

            <div class="noteInput" *ngIf="showNoteForm">
                <input
                    #noteInput
                    (keyup.enter)="addNote(noteInput.value)"
                    type="text"
                    class="form-control"
                />
                <button
                    class="btn btn-primary"
                    (click)="addNote(noteInput.value)"
                >
                    OK
                </button>
            </div>
        </div>

        <div
            class="addedNotes"
            *ngIf="careRecipientStats.card.notes.length > 0"
        >
            <ng-container *ngFor="let note of displayedNotes">
                <strong class="commentTime">
                    {{ note.commentTime | moment : "l LTS" }}
                </strong>
                <p class="comment">
                    {{ note.comment }}
                </p>
            </ng-container>
        </div>
    </div>
</div>

<!--Delete card dialog-->
<ng-template #confirmDeleteDialog let-modal>
    <div class="modal-header">
        <h2 style="color: #e8413b">{{ "WARNING" | translate }}!</h2>
        <span class="float-right cursorPointer" (click)="modal.dismiss()">
            <span class="fa-sharp fa-solid fa-x"></span>
        </span>
    </div>

    <section class="deleteDescription">
        <p>
            {{ "DELETE_CARE_RECIPIENT_CARD_DESC" }}
            <!--By confirming the deletion of this card, all the card related
            configuration data will be lost-->
            <strong
                >{{ "PERMANENTLY_LOWERCASE" | translate
                }}<!--permanently--></strong
            >
        </p>

        <h5 *ngIf="!deleteDialogErrorMsg">
            <strong style="color: #1d83e9">{{
                "ACTION_CANT_BE_UNDONE" | translate
            }}</strong>
        </h5>
    </section>

    <ng-container *ngIf="deleteDialogErrorMsg">
        <div class="alert alert-danger" id="dialogErrorMsg" role="alert">
            {{ deleteDialogErrorMsg | translate }}
        </div>
    </ng-container>

    <div class="buttonRow mb-3">
        <ng-container *ngIf="deleteCardConfirmation">
            <button
                type="button"
                class="btn btn-danger deleteCardBtn float-left"
                (click)="deleteCard()"
            >
                <span
                    [ngClass]="
                        cardDeleting
                            ? 'fad fa-spinner-third fa-spin'
                            : 'fa-light fa-trash-alt'
                    "
                ></span>
                {{ (cardDeleting ? "DELETING" : "CONFIRM_DELETE") | translate }}
            </button>
            <button
                type="button"
                class="btn btn-secondary cancelDeleteBtn float-right"
                (click)="modal.dismiss()"
            >
                {{ "CANCEL" | translate }}
            </button>
        </ng-container>
    </div>
</ng-template>
