export class AnnaPerennaMonitorProfile {
    name: string;
    id: string;
    profileType: number;
    bedOut: string;
    roomOut: string;
    maxToiletTime: string;
    maxFloorTime: number;
    inactiveStart: string;
    inactiveEnd: string;
    superActiveStart: string;
    superActiveEnd: string;
    fallRecognition:number;
    twoPersonsInRoom: number;
    exitNightTime: number;
    sendImage: number;
    monitoringEnabled: boolean;
    bedOutControl: number;
}