import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private languageSource = new BehaviorSubject<string>(localStorage.getItem('language') || 'en');
  currentLanguage = this.languageSource.asObservable();

  constructor() { }

  setLanguage(language: string) {
    localStorage.setItem('language', language);
    this.languageSource.next(language);
  }
}
