<div id="container">
    <h4 class="mainDarkBlueText font-weight-normal">
        {{ "AVERAGE_ALERT_DURATIONS" | translate }}
    </h4>

    <div id="chartContainer">
        <div id="chartContent">
            <div class="d-flex">
                <div>
                    <small id="startTime" class="grayText">0 s</small>
                </div>
                <div class="ml-auto">
                    <small class="grayText">{{
                        ("TOTAL_ALERT_TIME" | translate) +
                            ": " +
                            (avgTotalDuration | formatSeconds)
                    }}</small>
                </div>
            </div>
            <canvas id="averageAlertDurationChart"></canvas>
        </div>
    </div>

    <div id="durationCardsContainer">
        <div class="durationCard" style="border-color: rgb(255, 118, 74)">
            <h5 class="durationCardHeader">
                {{ labels.technicalDurationLabel }}
            </h5>
            <h3 class="durationTime">
                {{ durations.avgTechDurationSeconds | formatSeconds }}
            </h3>
        </div>
        <div class="durationCard ml-3" style="border-color: rgb(255, 219, 160)">
            <h5 class="durationCardHeader">{{ labels.queueTimeLabel }}</h5>
            <h3 class="durationTime">
                {{ durations.avgQueueTimeSeconds | formatSeconds }}
            </h3>
        </div>
        <div class="durationCard ml-3" style="border-color: rgb(112, 212, 252)">
            <h5 class="durationCardHeader">{{ labels.reservedTimeLabel }}</h5>
            <h3 class="durationTime">
                {{ durations.avgReservedTimeSeconds | formatSeconds }}
            </h3>
        </div>
        <div class="durationCard ml-3" style="border-color: rgb(75, 194, 186)">
            <h5 class="durationCardHeader">{{ labels.careTimeLabel }}</h5>
            <h3 class="durationTime">
                {{ durations.avgCareTimeSeconds | formatSeconds }}
            </h3>
        </div>
    </div>
</div>
