export class ResidentCompany {
  residentId: number;
  companyId: string;
}

export class ResidentDisplayName {
  residentId: number;
  displayName: string;
}

export class ResidentDevice {
  residentId: number;
  deviceId: string;
  devClass: number;
  devName: string;
  devStatus: string;
  locationId: string;
  locationName: string;
}

export class SetResidentDevice {
  residentId: number;
  deviceId: string;
}

export class UnsetResidentDevice {
  residentId: number;
  deviceId: string;
  locationId: string;
}

export class ResidentInfo {
  residentId: number;
  fName: string;
  mName: string;
  lName: string;
  displayName: string;
  ssnumber: string;
  company: string;
  address: string;
  zipCode: string;
  city: string;
  doorCode: string;
  phoneCell: string;
  drivingDirections: string;
  description: string;
  importantInformation: string;
}

export class AllResidents {
  residentId: number;
  residentName: string;
  address: string;
  companyId: string;
  company: string;
  residentDevices: number;
}

/**
 * Flagged residents table sorting keys for the API side sorting
 */
export enum FlaggedResidentSorter {
  Name,
  Address,
  Company,
  PhoneHome,
  PhoneCell,
  Date,
}

/**
 * New residents table sorting keys for the API side sorting
 */
export enum NewResidentSorter {
  Name,
  Address,
  DisplayName,
  Date,
}

/**
 * All residents table sorting keys for the API side sorting
 */
export enum AllResidentSorter {
  Name = 1,
  Address,
  Company,
  SSnumber,
  PhoneHome,
  PhoneCell,
  DeviceCount,
}
