<mat-card>
    <mat-card-header>
        <mat-card-title>Do you really want to delete this account?</mat-card-title>
    </mat-card-header>
    <mat-card-content mat-dialog-content>
        <mat-list role="list">
            <mat-list-item role="listitem">
                <span class="w-50">{{ 'STAFF_ID' | translate}}:</span> <span>{{ data.user.id }}</span>
            </mat-list-item>
            <mat-list-item role="listitem">
                <span class="w-50">{{ 'FIRST_NAME' | translate}}: </span> <span>{{ data.user.firstName }}</span>
            </mat-list-item>
            <mat-list-item role="listitem">
                <span class="w-50">{{ 'LAST_NAME' | translate}}:</span> <span> {{ data.user.familyName }}</span>
            </mat-list-item>
            <mat-list-item role="listitem">
                <span class="w-50">{{ 'USING_LEVEL' | translate}}:</span> <span>{{ data.user.usingLevel }}</span>
            </mat-list-item>
            <mat-list-item role="listitem">
                <span class="w-50">{{ 'USERNAME' | translate}}:</span> <span>{{ data.user.username }}</span>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
    <mat-card-actions mat-dialog-actions class="form-group d-flex justify-content-between">
        <button type="reset" class="btn btn-outline-primary" (click)="onNoClick()" cdkFocusInitial><span
                class="fal fa-times"></span> {{'CANCEL' |translate}}</button>
        <button class="btn btn-outline-danger" [mat-dialog-close]="true"><span
                class="fa-light fa-fw fa-trash-alt"></span> {{'DELETE' |translate}}</button>
    </mat-card-actions>
</mat-card>
