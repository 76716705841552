import { FormsModule } from "@angular/forms";
import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { NgClass, NgIf } from "@angular/common";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CareRecipientCard } from "app/models/statistics.model";
import { ApiService } from "app/services/api.service";
import { DROPDOWN_MULTISELECT_SETTINGS } from "app/shared/ss-multiselect-dropdown-settings.shared";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { StatisticsService } from "app/services/statistics.service";
import { ThrottleButtonClickDirective } from "app/directives/throttle-button-click.directive";
import { Subscription, forkJoin } from "rxjs";
import {
  NgxBootstrapMultiselectModule,
  IMultiSelectOption,
  IMultiSelectTexts,
} from "ngx-bootstrap-multiselect";

export interface IDialogData {
  title: string;
  card: CareRecipientCard;
  editMode?: boolean;
}

@Component({
  selector: "care-recipient-card-form-dialog",
  templateUrl: "./care-recipient-card-form-dialog.component.html",
  styleUrls: ["./care-recipient-card-form-dialog.component.css"],
  standalone: true,
  imports: [
    TranslateModule,
    NgxBootstrapMultiselectModule,
    FormsModule,
    ThrottleButtonClickDirective,
    NgIf,
    NgClass,
  ],
})
export class CareRecipientCardFormDialogComponent implements OnInit, OnDestroy {
  constructor(
    private api: ApiService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<CareRecipientCardFormDialogComponent>,
    private statistics: StatisticsService,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData
  ) {
    this.card = this.data.card;
    if (this.data.editMode) {
      this.editMode = true;
      this.originalCardName = this.card.name;
    }
  }

  card: CareRecipientCard;
  subscription: Subscription;

  originalCardName: string = "";
  deviceSelectSettings = DROPDOWN_MULTISELECT_SETTINGS;
  deviceSelectOptions: IMultiSelectOption[];
  cardsAvailableToBeAdded: boolean = false;
  maximumCardLimit: number;
  saveErrorMsg: string = "";
  initErrorMsg: string = "";

  editMode: boolean = false;
  saveDisabled: boolean = true;
  saving: boolean = false;
  initDataLoaded: boolean = false;

  deviceSelectTexts: IMultiSelectTexts = {
    checkAll: this.translate.instant("FILTER_CHECK_ALL"),
    uncheckAll: this.translate.instant("FILTER_UNCHECK_ALL"),
    checked: this.translate.instant("FILTER_CHECKED"),
    checkedPlural: this.translate.instant("FILTER_CHECKED"),
    searchPlaceholder: this.translate.instant("SEARCH"),
    allSelected: this.translate.instant("FILTER_ALL_SELECTED"),
    defaultTitle: this.translate.instant("DEVICE"),
  };

  saveCard(): void {
    this.saving = true;
    this.editMode ? this.updateCard(this.card) : this.createCard(this.card);
  }

  createCard(card: CareRecipientCard): void {
    this.statistics.createCareRecipientCard(card).subscribe(
      (created) => {
        if (created) {
          this.dialogRef.close("CARE_RECIPIENT_CARD_CREATED_SUCCESS");
        } else {
          this.saveErrorMsg = "ERROR";
        }
        this.saving = false;
      },
      (status) => {
        console.log(status.error);
        this.saveErrorMsg = "ERROR";
        this.saving = false;
      }
    );
  }

  updateCard(card: CareRecipientCard): void {
    this.statistics.updateCareRecipientCard(card).subscribe(
      (updated) => {
        if (updated) {
          const updatedCard = card;
          this.dialogRef.close(updatedCard);
        } else {
          this.saveErrorMsg = "ERROR";
        }
        this.saving = false;
      },
      (status) => {
        console.log(status.error);
        this.saveErrorMsg = "ERROR";
        this.saving = false;
      }
    );
  }

  closeForm(): void {
    this.dialogRef.close();
  }

  isCardSaveDisabled(): void {
    if (!this.deviceSelectOptions) {
      this.initErrorMsg = this.translate.instant("ERROR");
      this.saveDisabled = true;
    } else if (!this.editMode && !this.cardsAvailableToBeAdded) {
      const msg1 = this.translate.instant("USER_CARD_LIMIT_REACHED"); // The card user limit has been reached and a new card cannot be added.
      const msg2 = this.translate.instant("USER_CAN_HAVE"); // A user can have maximum of
      const msg3 = this.translate.instant("CARDS_AT_ONCE"); // cards at the same time.
      this.initErrorMsg = `${msg1} ${msg2} ${this.maximumCardLimit} ${msg3}`;
      this.saveDisabled = true;
    } else {
      this.saveDisabled = false;
    }
  }

  ngOnInit(): void {
    const filterOptions$ = this.api.getReportFilteringOptions();
    const cardsAvailability$ = this.statistics.isCareRecipientCardsAvailable();

    // Get select options for the devices and check cards availability
    this.subscription = forkJoin([
      cardsAvailability$,
      filterOptions$,
    ]).subscribe(
      ([cardsAvailabilityData, filterOptions]) => {
        this.deviceSelectOptions = filterOptions.devices.sort(
          (a: any, b: any) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
        );
        this.cardsAvailableToBeAdded = cardsAvailabilityData.available;
        this.maximumCardLimit = cardsAvailabilityData.maximumLimit;
        this.isCardSaveDisabled();
        this.initDataLoaded = true;
      },
      (error) => {
        console.log(error);
        this.isCardSaveDisabled();
        this.initDataLoaded = true;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
