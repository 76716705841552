import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { Location, NgIf, NgFor, NgClass, SlicePipe } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AnimationCollapse } from '../../../animations';
import { UntypedFormBuilder, Validators, UntypedFormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'system-firmware',
    templateUrl: './system-firmware.component.html',
    styleUrls: ['./system-firmware.component.css'],
    animations: [
        AnimationCollapse
    ],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, NgFor, NgClass, SlicePipe, TranslateModule]
})
export class SystemFirmwareComponent implements OnInit, OnDestroy {

  //Init global variables and services
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private location: Location,
    private translate: TranslateService,
    private fb: UntypedFormBuilder
  ) { };

  formData: FormData = new FormData();
  fwFormModel: FwModel = new FwModel();
  fwFormEditMode: boolean = false;
  swVersions: any;
  uploadFirmwareForm: UntypedFormGroup;
  selectedFirmwareId: any;
  swShowCount: number = 10;

  formSubmitSubject = new Subject();

  createUploadFirmwareForm(): void {
    this.uploadFirmwareForm = this.fb.group({
      deviceType: ["31-13", Validators.required],
      hwVersion: ['', Validators.required],
      swVersion: ['', Validators.required],
      swType: ["1", Validators.required],
      description: ['', Validators.required],
      changeLog: '',
      disabled: false,
      productionSw: false,
      recommendedSw: false,
      fwFile: [null, Validators.required]
    })
  }

  fileChange(event) {
    let files: FileList = event.target.files;
    if (files.length > 0) {
      let file: File = files[0];
      this.formData.append('file', file, file.name);
    }
  }

  saveFirmware(): void {
    this.fwFormModel = this.uploadFirmwareForm.value;
    this.fwFormModel.swType = Number(this.fwFormModel.swType);
    this.fwFormModel.firmwareId = this.selectedFirmwareId;
    this.formData.append('request', JSON.stringify(this.fwFormModel));
    this.api.setFirmWare(this.formData, this.fwFormEditMode)
      .subscribe(res => {
        this.resetFwForm();
        this.formData = new FormData();
        this.loadFirmwareList();
      })

  }

  deleteFw(): void {
    let id = this.selectedFirmwareId;
    this.api.deleteFirmware(id)
      .subscribe(res => {
        this.formData = new FormData();
        this.resetFwForm();
        this.loadFirmwareList();
        this.fwFormEditMode = false;
      })
  }

  resetFwForm(): void {
    this.fwFormEditMode = false;
    this.selectedFirmwareId = "";
    this.uploadFirmwareForm.reset({
      deviceType: "31-13",
      swType: "1",
      disabled: false,
      productionSw: false,
      recommendedSw: false,
      changeLog: '',
      fwFile: null
    })
    //Make sure that the fwFile field has required validator after resets
    this.uploadFirmwareForm.get('fwFile').setValidators(Validators.required);
    this.uploadFirmwareForm.get('fwFile').updateValueAndValidity();
  }

  deviceTypeSelectionChange(): void {
    if (this.uploadFirmwareForm.value.deviceType !== '31-13' && this.uploadFirmwareForm.value.deviceType !== '31-14' && this.uploadFirmwareForm.value.deviceType !== '31-15') {
      this.uploadFirmwareForm.patchValue({
        swType: '4'
      })
    } else {
      this.uploadFirmwareForm.patchValue({
        swType: '1'
      })
    }
  }

  getDevType(type: string): string {
    return this.api.getDeviceType(type, false);
  }

  selectSw(sw: FwModel): void {
    this.selectedFirmwareId = sw.firmwareId;
    this.fwFormEditMode = true;
    console.log(sw);
    this.uploadFirmwareForm.reset({
      deviceType: sw.deviceType,
      hwVersion: sw.hwVersion,
      swVersion: sw.swVersion,
      swType: sw.swType.toString(),
      description: sw.description,
      changeLog: sw.changeLog,
      disabled: sw.disabled,
      productionSw: sw.productionSw,
      recommendedSw: sw.recommendedSw,
      fwFile: null
    })
    //Remove required validator from the fwFile field when editing existing firmware since the fwFile field is always null in this case.
    this.uploadFirmwareForm.get('fwFile').clearValidators();
    this.uploadFirmwareForm.get('fwFile').updateValueAndValidity();
  }

  loadFirmwareList(): void {
    let data = {
      "showDisabled": true,
      "productionSwOnly": false
    }
    this.api.getFirmwares(data)
      .subscribe(res => {
        this.swVersions = res.reverse();
      })
  }

  ngOnInit() {
    this.loadFirmwareList();
    this.createUploadFirmwareForm();

    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject
      .pipe(throttleTime(3000))
      .subscribe(() => {
        this.saveFirmware();
      });
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }
}

class FwModel {
  firmwareId: number;
  deviceType: string = "31-13";
  hwVersion: number = 2;
  swVersion: number;
  swType: any = "1";
  description: string;
  changeLog: string;
  disabled: boolean = false;
  productionSw: boolean = false;
  recommendedSw: boolean = false;
}
