import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    const errors: ValidationErrors = {};

    // Check for whitespaces
    if (/\s/.test(value)) {
      errors.passwordWhitespace = true;
      return errors;
    }

    // Check that length is at least 6 characters
    if (value.length < 6) {
      errors.passwordLength = true;
      return errors;
    }

    return null;
  };
}
