export class OnOnCameraGroup {
    id: string;
    tenantId: string;
    lyraId: string;
    name: string;
    cameras: OnOnCamera[];
    state: OnOnCameraGroupState;
    alerts: OnOnCameraGroupAlert[];
    activeProfile: OnOnProfile;
    nurseInRoom: boolean;
    schedule: OnOnProfileSchedule[];
}

export class OnOnCamera {
    id: string;
    name: string;
    width: number;
    height: number;
    framesPerSecond: number;
    cameraGroupId: string;
}

export class OnOnProfileSchedule {
    profileId: string;
    name: string;
    startTime: any;
    stopTime: any;
}

export class OnOnCameraGroupState {
    id: string;
    state: string;
    time: string;
}

export class OnOnCameraGroupAlert {
    alertId: string;
    type: any;
    tenandId: string;
    taskStartedBy: string;
}

export class OnOnProfile {
    id: string;
    tenantId: string;
    name: string;
    enabled: boolean = true;
    bedAlerts: OnOnBedAlert = new OnOnBedAlert();
    exitAlerts: OnOnProfileAlert = new OnOnProfileAlert();
    toiletAlerts: OnOnProfileAlert = new OnOnProfileAlert();;
    fallAlerts: OnOnProfileAlert = new OnOnProfileAlert();;
    manyPersonsAlerts: OnOnProfileAlert = new OnOnProfileAlert();;
    sendImages: boolean = false;
    sentImagesType: OnOnAlertImageTypes = OnOnAlertImageTypes.VideoPictures;

}

class OnOnBedAlert {
    mode: OnOnBedAlertModes = OnOnBedAlertModes.Disabled;
    delayMinutes: number = 0;
}

export enum OnOnAlertImageTypes {
    VideoPictures = "VideoPictures",
    SkeletonAnimation = "SkeletonAnimation"
}

export enum OnOnBedAlertModes {
    Disabled = "disabled",
    AlertWithDelay = "alertWithDelay",
    AlertAlwaysOutsideBed = "alertAlwaysOutsideBed"
}

class OnOnProfileAlert {
    enabled: boolean = false;
    delayMinutes: number = 0;
}

export class OnOnZone {
    id: string;
    type: string;
    options: OnOnZoneOptions = new OnOnZoneOptions();
    cornerPoints: OnOnZonePoint[];
    polygonPoints: string;
    modified: boolean;
    toBeDeleted: boolean;
}

class OnOnZoneOptions {
    points: OnOnZonePoint[] = [];
    direction: any;
    directionalVertex: any;
}

class OnOnZonePoint {
    x: number;
    y: number;
}
