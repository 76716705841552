export class LocationModel {
  id: string;
  name: string;
  qualifier?: string;
  address: string;
  postCode: string;
  postOffice: string;
  mainUserGsmNumber: string;
  contactPhone: string;
  emaTagOnly: boolean;
  networkFailureState: boolean;
}

export class CompanyGroupLocation {
  company: string;
  companyAddress: string;
  companyID: string;
  locationAddress: string;
  locationID: string;
  locationName: string;
}

export class LocationEmaSettings {
  alertTimers: any;
  alertPriorities: any;
  alertNotes: any;
  alarmOptions: any;
  loginKeys: any;
  alertUi: any;
}
