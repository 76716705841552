import { IAlarmReasonTranslations } from "app/models/alarm-reasons.model";
import { MatDialog } from "@angular/material/dialog";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { MomentPipe, SecondsToReadableFormatPipe } from "app/pipes/pipes";
import { NgFor, NgIf, NgStyle, NgClass } from "@angular/common";
import { StatisticsService } from "app/services/statistics.service";
import {
  CareRecipientCardFormDialogComponent,
  IDialogData,
} from "../care-recipient-card-form-dialog/care-recipient-card-form-dialog.component";
import {
  CareRecipientCard,
  CareRecipientStatistics,
  ICareRecipientCardNote,
} from "app/models/statistics.model";
import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
  Renderer2,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";

@Component({
  selector: "care-recipient-card",
  templateUrl: "./care-recipient-card.component.html",
  styleUrls: ["./care-recipient-card.component.css"],
  standalone: true,
  imports: [
    TranslateModule,
    SecondsToReadableFormatPipe,
    MomentPipe,
    NgClass,
    NgStyle,
    NgIf,
    NgFor,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareRecipientCardComponent implements OnInit, AfterViewInit {
  constructor(
    private statistics: StatisticsService,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  @ViewChild("settingsDropdown") dropdown: ElementRef;
  @ViewChild("dropdownBtn") dropdownBtn: ElementRef;
  @ViewChild("stackedAlarmBar", { static: false })
  stackedAlarmBar: ElementRef;

  @Input() careRecipientStats: CareRecipientStatistics;
  @Input() translations: IAlarmReasonTranslations;
  @Input() longestAvgWaitTime: number;
  @Input() longestAvgCareTime: number;

  alarmReasonLabels: string[] = [];
  displayedNotes: ICareRecipientCardNote[] = [];

  alarmReasonBlockColors = ["#70d4fc", "#0088e3", "#ffb399", "#ffdba0"];
  maxColorIndex = this.alarmReasonBlockColors.length - 1;
  currentColorIndex: number = 0;
  showCardSettings: boolean = false;
  showNoteForm: boolean = false;
  deleteCardConfirmation: boolean = false;
  cardDeleting: boolean = false;
  deleteDialogErrorMsg: string;
  noteErrorMsg: string;

  avgWaitTimeBarStyles: IAverageTimeBarStyles = {};
  avgCareTimeBarStyles: IAverageTimeBarStyles = {};

  /**
   * Card dropdown hide handler
   * @param event Click event
   */
  @HostListener("document:click", ["$event"])
  handleHideDropdown(event: Event): void {
    if (this.dropdown && this.showCardSettings) {
      // Check if clicked element is dropdown option, dropdown button or icon inside the button
      const isOption = this.dropdown.nativeElement.contains(event.target);
      const isDropdownBtn = this.dropdownBtn.nativeElement === event.target;
      const isBtnIcon = this.dropdownBtn.nativeElement.contains(event.target);

      // Hide the dropdown if the clicked element isn't any of the checked elements
      if (!isOption && !isDropdownBtn && !isBtnIcon) {
        this.showCardSettings = false;
      }
    }
  }

  openEditCardDialog(): void {
    const dialogData: IDialogData = {
      title: "EDIT_CARD",
      card: JSON.parse(JSON.stringify(this.careRecipientStats.card)),
      editMode: true,
    };

    const dialogRef = this.dialog.open(CareRecipientCardFormDialogComponent, {
      width: "45%",
      data: dialogData,
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((updatedCardData: CareRecipientCard) => {
      if (updatedCardData) {
        this.careRecipientStats.card = updatedCardData;
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  openDeleteCardDialog(dialogElement: any): void {
    this.deleteCardConfirmation = true;
    this.modalService
      .open(dialogElement, {
        centered: true,
      })
      .result.then(
        () => {
          this.deleteCardConfirmation = false; // Set delete confirmation to false if dialog is closed or promise is rejected
        },
        () => {
          this.deleteCardConfirmation = false;
        }
      );
  }

  deleteCard(): void {
    if (this.deleteCardConfirmation) {
      const cardId = this.careRecipientStats.card.id;
      const deleteErrorMsg = "ERROR";

      this.deleteDialogErrorMsg = "";
      this.cardDeleting = true;

      this.statistics.deleteCareRecipientCard(cardId).subscribe(
        (deleteSuccess) => {
          if (deleteSuccess) {
            this.cardDeleting = false;
            this.statistics.removedCareRecipientCardId$.next(cardId);
            this.modalService.dismissAll();
          } else {
            this.cardDeleting = false;
            this.deleteDialogErrorMsg = deleteErrorMsg;
          }
        },
        (errorStatus) => {
          console.log(errorStatus.error);
          this.cardDeleting = false;
          this.deleteDialogErrorMsg = deleteErrorMsg;
        }
      );
    }
  }

  addNote(text: string): void {
    this.noteErrorMsg = "";
    // If text is not empty, make a request, else close the form
    if (text) {
      const note: ICareRecipientCardNote = {
        comment: text,
        commentTime: new Date(),
      };
      this.statistics
        .addNoteToCareRecipientCard(this.careRecipientStats.card.id, note)
        .subscribe(
          (addSuccess) => {
            if (addSuccess) {
              this.displayedNotes.unshift(note);
              this.changeDetectorRef.markForCheck();
              this.showNoteForm = false;
            } else {
              this.noteErrorMsg = "NOTE_ADD_ERROR";
            }
          },
          (errorStatus) => {
            console.log(errorStatus.error);
            this.noteErrorMsg = "NOTE_ADD_ERROR";
          }
        );
    } else {
      this.showNoteForm = false;
    }
  }

  /**
   * Create horizontal stacked bar for showing percentage ratio of most common alarm reasons
   */
  createStackedAlarmReasonBar(): void {
    const totalAlarmReasons = this.careRecipientStats.data.totalAlarmReasons;
    const alertCountByAlarmReasons =
      this.careRecipientStats.data.alertCountByAlarmReasons.reverse();

    for (let alarmReason of alertCountByAlarmReasons) {
      // Create bar element for the alarm reason stacked bar
      const bar: HTMLElement = this.renderer.createElement("span");
      const reason = this.translateAlarmReason(alarmReason.key);

      // Calculate bar width and pick the background color
      const percentage = (alarmReason.alarmCount / totalAlarmReasons) * 100;
      const color = this.pickBarColor();

      this.renderer.setStyle(bar, "width", percentage + "%");
      this.renderer.setStyle(bar, "background-color", color);
      this.renderer.setAttribute(bar, "title", reason);

      this.renderer.addClass(bar, "bar");

      // Add bar to the stacked bar element
      this.renderer.appendChild(this.stackedAlarmBar.nativeElement, bar);
    }

    // Prevent visual bug by adding animation only if there's any alarm reasons included
    if (totalAlarmReasons > 0) {
      this.renderer.addClass(
        this.stackedAlarmBar.nativeElement,
        "expandAnimation"
      );
    }
  }

  // Not in use
  createAlarmBarTooltip(data: { color: string; text: string }[]): void {
    const tooltip: HTMLElement = this.renderer.createElement("div");

    this.renderer.addClass(tooltip, "alarmReasonTooltip");

    data.forEach((obj) => {
      const label: HTMLElement = this.renderer.createElement("div");
      const colorSquare = this.renderer.createElement("div");
      const text = this.renderer.createElement("small");

      this.renderer.addClass(label, "tooltipLabel");
      this.renderer.addClass(colorSquare, "square");
      this.renderer.addClass(text, "labelText");

      this.renderer.setStyle(colorSquare, "background-color", obj.color);

      this.renderer.appendChild(text, this.renderer.createText(obj.text));
      this.renderer.appendChild(label, colorSquare);
      this.renderer.appendChild(label, text);
      this.renderer.appendChild(tooltip, label);
    });

    this.renderer.appendChild(this.stackedAlarmBar.nativeElement, tooltip);
  }

  sum(numbers: number[]): number {
    return numbers.reduce((acc, cv) => {
      return acc + cv;
    }, 0);
  }

  /**
   * Pick color for the alarm reason bar block
   * @returns Hexadecimal representation of color
   */
  pickBarColor(): string {
    const color = this.alarmReasonBlockColors[this.currentColorIndex];
    if (this.currentColorIndex === this.maxColorIndex)
      this.currentColorIndex = 0;
    else this.currentColorIndex++;
    return color;
  }

  setAverageTimeBarStyles(): void {
    const [waitTimePercentage, careTimePercentage] =
      this.setAverageTimeBarWidths();

    this.setAverageTimeBarAnimations(waitTimePercentage, careTimePercentage);
  }

  setAverageTimeBarAnimations(
    waitTimePercentage: number,
    careTimePercentage: number
  ): void {
    const durationSeconds = 0.6;
    const transition = "linear";

    document.styleSheets[0].insertRule(`
      @keyframes expandAvgWaitTimeBar {
        0% { width: 0}
        100% {${waitTimePercentage > 100 ? 100 : waitTimePercentage}}
      }`);

    document.styleSheets[0].insertRule(`
      @keyframes expandAvgCareTimeBar {
        0% { width: 0}
        100% {${careTimePercentage > 100 ? 100 : careTimePercentage}}
      }`);

    this.avgWaitTimeBarStyles.animation = `expandAvgWaitTimeBar ${durationSeconds}s ${transition} 1`;
    this.avgCareTimeBarStyles.animation = `expandAvgCareTimeBar ${durationSeconds}s ${transition} 1`;
  }

  setAverageTimeBarWidths(): number[] {
    const waitTime = this.careRecipientStats.data.avgWaitTimeSeconds;
    const careTime = this.careRecipientStats.data.avgCareTimeSeconds;

    const waitTimePercentage = (waitTime / this.longestAvgWaitTime) * 100 || 0;
    const careTimePercentage = (careTime / this.longestAvgCareTime) * 100 || 0;

    // Max width cannot exceed 100%
    this.avgWaitTimeBarStyles.width = `${
      waitTimePercentage > 100 ? 100 : waitTimePercentage
    }%`;
    this.avgCareTimeBarStyles.width = `${
      careTimePercentage > 100 ? 100 : careTimePercentage
    }%`;

    return [waitTimePercentage, careTimePercentage];
  }

  translateAlarmReason(key: string): string {
    let translation = "";
    if (this.translations) {
      translation =
        this.translations.alarmReason.find((obj) => obj.key === key)
          .translation ?? "";
    }
    return translation;
  }

  formatAlarmReasonLabels(): void {
    const totalAlarmReasons = this.careRecipientStats.data.totalAlarmReasons;
    const alertCountByAlarmReasons =
      this.careRecipientStats.data.alertCountByAlarmReasons;

    for (const alarmReason of alertCountByAlarmReasons) {
      const translation = this.translateAlarmReason(alarmReason.key);

      const percentage = (
        (alarmReason.alarmCount / totalAlarmReasons) *
        100
      ).toFixed(0);

      const info = translation + " " + percentage + "%";

      this.alarmReasonLabels.push(info);
    }
  }

  removeHorizontalBarAnimations(): void {
    this.avgWaitTimeBarStyles.animation = "none";
    this.avgCareTimeBarStyles.animation = "none";
    this.renderer.removeClass(
      this.stackedAlarmBar.nativeElement,
      "expandAnimation"
    );
    this.changeDetectorRef.markForCheck();
  }

  sortNotesByLatestFirst(): void {
    this.displayedNotes = this.careRecipientStats.card.notes.reverse();
  }

  ngAfterViewInit(): void {
    this.createStackedAlarmReasonBar();

    // Remove horizontal bar animation after they are displayed once on component initialization
    // Otherwise animations will be triggered every time when card is dragged or drag sorted
    setTimeout(() => {
      this.removeHorizontalBarAnimations();
    }, 600);
  }

  ngOnInit(): void {
    this.setAverageTimeBarStyles();
    this.formatAlarmReasonLabels();
    this.sortNotesByLatestFirst();
  }
}

interface IAverageTimeBarStyles {
  width?: string;
  animation?: string;
}
