<ng-container *ngIf="statistics.alertsTabDataLoaded; else elseBlock">
    <div class="row">
        <div class="col-3" style="max-width: none; flex: 0 0 382px">
            <div>
                <alerts-by-location
                    [locationAlerts]="alertStatisticsData.locationAlerts"
                >
                </alerts-by-location>
            </div>
            <div class="mt-3">
                <alerts-per-alert-type
                    [alertTypeAlerts]="alertStatisticsData.alertsByTypes"
                >
                </alerts-per-alert-type>
            </div>
        </div>
        <div class="col">
            <div>
                <alerts-per-day
                    [alertsByDays]="alertStatisticsData.alertsByDays"
                >
                </alerts-per-day>
            </div>
            <div class="mt-3">
                <alerts-per-hour
                    [alertsByHours]="alertStatisticsData.alertsByHours"
                ></alerts-per-hour>
            </div>
            <div class="mt-3">
                <average-alert-durations
                    [durations]="alertStatisticsData.averageDurations"
                ></average-alert-durations>
            </div>

            <div class="mt-3">
                <alerts-per-care-recipient
                    [deviceAlerts]="alertStatisticsData.deviceAlerts"
                ></alerts-per-care-recipient>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #elseBlock>
    <img
        class="fadeLoader centerLoader logoSmall"
        src="assets/img/loading_logo.svg"
    />
</ng-template>
