import { Component, Inject, OnInit, forwardRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-delete-system-user-dialog',
    templateUrl: './delete-system-user-dialog.component.html',
    standalone: true,
    imports: [MatCardModule, MatDialogModule, MatListModule, forwardRef(() => DeleteSystemUserDialogComponent), TranslateModule]
})
export class DeleteSystemUserDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeleteSystemUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
