import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent, SubscriptionLike, PartialObserver } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private cookies: CookieService
    ) { }

    //Intercept every HTTP request and add Authorization token header
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //Get the session token from the cookies
        let sessionToken = this.cookies.get("session-token");
        //If token is found add it to authorization header
        if (sessionToken) req = req.clone({ setHeaders: { Authorization: sessionToken, 'Cache-Control': 'no-cache', Pragma: 'no-cache' } });
        //Add base URL into request URL
        req = req.clone({
            url: environment.apiUrl + req.url
        })
        //Continue with the request
        return next.handle(req).pipe(
            map((event: any) => {

              //Skip response decoding if event body is blob
              if(event.body instanceof Blob){
                return event;
              }

                //Decode specific HTML entities from the responses
                if (event.body) {
                    let bodyStr = this.decodeHTMLEntities(JSON.stringify(event.body));
                    event = event.clone({ body: JSON.parse(bodyStr) })
                }
                return event;
            }));
    }

    decodeHTMLEntities(str) {
        var entities = [
            ['amp', '&'],
            ['lt', '<'],
            ['gt', '>'],
            ['nbsp', ' '],
            ['quot', '\\"'],
            ['#039', '\'']
        ];
        for (var i = 0, max = entities.length; i < max; ++i)
            str = str.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);
        return str;
    }
}
