<div class="row">
    <div [ngClass]="{'col-sm-6': selectedUser, 'col-sm-12': !selectedUser}">
        <div class="card">
            <div class="card-header-white header-underline">
                <h6 class="white-header-text"><span class="fa-light fa-fw fa-users"></span>&nbsp;&nbsp;{{'SYSTEM_USERS'
                    |translate}}</h6>
            </div>
            <div class="card-body-white">
                <div>
                    <button class="btn btn-outline-primary gray-bg" (click)="addUser()"><span
                            class="fa-light fa-fw fa-plus"></span> {{'NEW_USER' |translate}}</button>
                </div>
                <table class="table table-borderless table-hover">
                    <thead>
                        <tr class="border-bottom">
                            <th>ID</th>
                            <th>{{'FIRST_NAME' |translate}}</th>
                            <th>{{'LAST_NAME' |translate}}</th>
                            <th>{{'USERNAME' |translate}}</th>
                            <th>{{'USING_LEVEL' |translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user of systemUsers" class="cursorPointer" (click)="editUser(user)">
                            <td>{{user.id}}</td>
                            <td>{{user.firstName}}</td>
                            <td>{{user.familyName}}</td>
                            <td>{{user.username}}</td>
                            <td>{{getUsingLevelStr(user.usingLevel)}} ({{user.usingLevel}})</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-sm-6" *ngIf="selectedUser">
        <div class="card stickyCard">
            <div class="d-flex justify-content-between card-header-white header-underline align-items-center">
                <h6 class="align-self-center mb-0">{{selectedUser.id ? selectedUser.id : ('NEW_USER' |translate)}}</h6>
                <div class="cursorPointer d-flex w-25 align-self-stretch"
                    (click)="selectedUser = null">
                    <span class="fa-light fa-xmark ml-auto mr-3 cursorPointer align-self-center"></span>
                </div>
            </div>
            <div class="card-body-white">
                <div class="alert alert-success" *ngIf="saveSuccess">
                    {{'UPDATE_SUCCESS' |translate}}
                </div>
                <form [formGroup]="systemUserForm" (ngSubmit)="formSubmitSubject.next()">
                    <div class="form-group">
                        <label>{{'FIRST_NAME' |translate}}</label>
                        <input type="text" class="form-control" formControlName="firstName">
                    </div>
                    <div class="form-group">
                        <label>{{'LAST_NAME' |translate}}</label>
                        <input type="text" class="form-control" formControlName="familyName">
                    </div>
                    <div class="form-group">
                        <label>{{'USERNAME' |translate}}</label>
                        <input id="systemUserUsername" type="text" class="form-control" formControlName="username"
                            name="systemUserUsername">
                    </div>
                    <div class="form-group">
                        <mat-form-field>
                            <mat-label>{{'USING_LEVEL' |translate}}</mat-label>
                            <mat-select formControlName="usingLevel">
                                <mat-option value="150">{{'API_USER' |translate}}</mat-option>
                                <mat-option value="160">{{'PRODUCTION_USER' |translate}}</mat-option>
                                <mat-option value="200">{{'ADMIN_USER' |translate}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <label>{{'PASSWORD' |translate}}</label>
                        <input id="systemUserPassword" class="form-control" type="password"
                            placeholder="{{'PASSWORD' |translate}}" formControlName="password"
                            name="systemUserPassword">
                    </div>
                    <div class="form-group">
                        <button [disabled]="!systemUserForm.valid" type="submit" class="btn btn-primary"><span
                                class="fa-light fa-fw fa-floppy-disk"></span> {{'SAVE' |translate}}</button>
                        <button type="reset" class="btn btn-outline-primary" (click)="selectedUser = null"><span
                                class="fal fa-times"></span> {{'CANCEL' |translate}}</button>
                        <span class="btn btn-outline-danger float-right" (click)="deleteUser()"><span
                                class="fa-light fa-fw fa-trash-alt"></span> {{'DELETE' |translate}}</span>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
