import zoomPlugin from "chartjs-plugin-zoom";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart,
  ChartConfiguration,
  Legend,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  PieController,
  ArcElement,
  Tooltip,
} from "chart.js";

// Chart.js module registry
// Register Chart.js modules and plugin defaults globally to all charts
Chart.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  PieController,
  ArcElement,
  Tooltip
);

// Handler methods

// Not in use
const filterEveryOtherAxisTick = (value: any, index: number) => {
  if (index % 2 === 0) {
    return value;
  } else {
    return "";
  }
};

// Custom plugins

/**
 * Used to remove every second y-axis tick lines to avoid line amount excessivity
 */
const FilterEverySecondYAxisLines = {
  id: "filterEveryOtherAxisLines",
  beforeDraw(chart: any) {
    const yAxis = chart.scales.y;
    let ticks = yAxis.ticks;

    ticks.forEach((tick: any, index: number) => {
      const firstTick = index === 0;
      const evenTick = index % 2 === 0;

      if (evenTick || firstTick) {
        tick.markDelete = true;
      }
    });
    yAxis.ticks = ticks.filter((obj: any) => !obj.markDelete);
  },
};

// Chart configurations:

/**
 * Pie chart configuration for showing alerts handled by Emaac
 */
export const EMAAC_ALERTS_PIE_CHART_CONFIGS: ChartConfiguration<"pie"> = {
  type: "pie",
  data: {
    datasets: [],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
        position: "nearest",
      },
    },
  },
};

/**
 * Alarm reason stacked horizontal bar chart
 */
export const ALERTS_BY_ALARM_REASON_CHART_CONFIGS: ChartConfiguration<"bar"> = {
  type: "bar",
  data: {
    labels: [],
    datasets: [],
  },
  options: {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        display: false,
        max: 0,
      },
      y: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        beginAtZero: true,
      },
    },
    plugins: {
      zoom: {
        zoom: {
          drag: {
            enabled: true,
            backgroundColor: "rgb(220, 221, 223)",
          },
          mode: "xy",
        },
      },
    },
  },
  plugins: [ChartDataLabels], // zoomPlugin ],
};

/**
 * Configuration for the Alerts per hour stacked bar chart
 */
export const ALERTS_PER_HOUR_CHART_CONFIGS: ChartConfiguration<"bar"> = {
  type: "bar",
  data: {
    labels: [],
    datasets: [],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: true,
          drawBorder: false,
        },
        ticks: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        align: "start",
      },
      zoom: {
        zoom: {
          drag: {
            enabled: true,
            backgroundColor: "rgb(220, 221, 223)",
          },
          mode: "xy",
        },
      },
    },
  },
  plugins: [Legend, FilterEverySecondYAxisLines], //, zoomPlugin],
};

/**
 * Configuration for the Alerts per weekday stacked bar chart
 */
export const ALERTS_PER_DAY_CHART_CONFIGS: ChartConfiguration<"bar"> = {
  type: "bar",
  data: {
    labels: [],
    datasets: [],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: true,
          drawBorder: false,
        },
        ticks: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        align: "start",
      },

      zoom: {
        zoom: {
          drag: {
            enabled: true,
            backgroundColor: "rgb(220, 221, 223)",
          },
          mode: "xy",
        },
      },
    },
  },
  plugins: [Legend, FilterEverySecondYAxisLines], //, zoomPlugin],
};

/**
 * Configuration for the Average alert durations single horizontal bar chart
 */
export const AVERAGE_ALERT_DURATIONS_CHART_CONFIGS: ChartConfiguration<"bar"> =
  {
    type: "bar",
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      indexAxis: "y",
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          min: 0,
          stacked: true,
          display: false,
        },
        y: {
          stacked: true,
          display: false,
        },
      },
      hover: {
        mode: null,
      },
      plugins: {
        tooltip: {
          enabled: false,
        },
      },
    },
  };
