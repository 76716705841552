<ng-container *ngIf="statistics.alarmCenterTabDataLoaded; else elseBlock">
    <div class="row">
        <div class="col-3" style="max-width: none; flex: 0 0 382px">
            <div>
                <care-recipient-counter
                    [careRecipientCount]="
                        emaacStatisticsData.careRecipientCount
                    "
                ></care-recipient-counter>
            </div>
            <div class="mt-3">
                <emaac-alerts
                    [emaacAlertStats]="emaacStatisticsData.emaacAlertStats"
                ></emaac-alerts>
            </div>
            <div class="mt-3">
                <response-time-statistics
                    [resTimeStats]="
                        emaacStatisticsData.responseTimes.resTimeStats
                    "
                ></response-time-statistics>
            </div>
        </div>
        <div class="col">
            <div>
                <alerts-by-alarm-reason
                    [alertsByAlarmReasons]="
                        emaacStatisticsData.alertsByAlarmReasons
                    "
                    [translations]="alarmReasonTranslations"
                ></alerts-by-alarm-reason>
            </div>

            <div class="mt-3">
                <alerts-per-day
                    [alertsByDays]="emaacStatisticsData.alertsByDays"
                >
                </alerts-per-day>
            </div>
            <div class="mt-3">
                <alerts-per-hour
                    [alertsByHours]="emaacStatisticsData.alertsByHours"
                >
                </alerts-per-hour>
            </div>

            <div class="mt-3">
                <alert-response-times
                    [responseTimes]="
                        emaacStatisticsData.responseTimes.alertResponses
                    "
                >
                </alert-response-times>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #elseBlock>
    <img
        class="fadeLoader centerLoader logoSmall"
        src="assets/img/loading_logo.svg"
    />
</ng-template>
