import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SystemUser } from 'app/models/systemuser.model';
import { ValidateUsernameAsync } from 'app/services/validators.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteSystemUserDialogComponent } from './delete-system-user-dialog/delete-system-user-dialog.component';
import { Subject } from "rxjs";
import { throttleTime } from "rxjs/operators";
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'system-users',
    templateUrl: './system-users.component.html',
    styleUrls: ['./system-users.component.css'],
    standalone: true,
    imports: [NgClass, NgFor, NgIf, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, TranslateModule]
})
export class SystemUsersComponent implements OnInit, OnDestroy {

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog
  ) { };

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteSystemUserDialogComponent, {
      width: '50%',
      data: { user: this.selectedUser }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onConfirmDelete()
      }
    });
  }

  formSubmitSubject = new Subject();

  systemUsers: SystemUser[];
  selectedUser: SystemUser;
  systemUserForm: UntypedFormGroup;
  saveSuccess: boolean;

  loadUserList(): void {
    this.api.getSystemUsers().subscribe(res => {
      this.systemUsers = res;
    }, err => {

    })
  }

  addUser(): void {
    let newUser = new SystemUser();
    newUser.usingLevel = 200;
    this.editUser(newUser);
  }

  editUser(user: SystemUser): void {
    if (this.selectedUser !== undefined && this.selectedUser === user) {
      this.selectedUser = null;
      return
    }
    this.saveSuccess = false;
    this.selectedUser = user;
    this.systemUserForm = this.fb.group({
      firstName: user.firstName,
      familyName: user.familyName,
      username: [user.username, Validators.required],
      usingLevel: [user.usingLevel.toString(), Validators.required],
      password: ["", Validators.required]
    })

    //Remove password validation if user is being modified
    if (this.selectedUser.id) {
      this.systemUserForm.controls.password.clearValidators();
      this.systemUserForm.controls.password.updateValueAndValidity();
    }
  }

  getUsingLevelStr(usingLevel: number): string {
    switch (usingLevel) {
      case 150:
        return this.translate.instant("API_USER");
      case 160:
        return this.translate.instant("PRODUCTION_USER");
      case 200:
        return this.translate.instant("ADMIN_USER");
      default:
        break;
    }
  }

  saveSystemUser(): void {
    this.saveSuccess = false;
    let data = this.systemUserForm.value;
    if (this.selectedUser.id) {
      data.id = this.selectedUser.id;
      this.api.updateSystemUser(data).subscribe(res => {
        this.saveSuccess = true;
        this.loadUserList();
      }, err => {

      })
    } else {
      this.api.addSystemUser(data).subscribe(res => {
        this.loadUserList();
        this.selectedUser = null;
      }, err => {

      })
    }
  }

  deleteUser(): void {
    this.openDialog()
  }

  onConfirmDelete() {
    this.api.deleteSystemUer(this.selectedUser.id).subscribe(res => {
      this.loadUserList();
      this.selectedUser = null;
    })
  }

  ngOnInit(): void {
    this.loadUserList();

    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject
      .pipe(throttleTime(3000))
      .subscribe(() => {
        this.saveSystemUser();
      });
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }
}
