import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent, SubscriptionLike, PartialObserver } from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from './api.service';

@Injectable()
export class AuthGuard  {

    constructor(
        private api: ApiService,
        private router: Router,
        private cookies: CookieService
    ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.api.isAuth().pipe(
            map((res: Response) => {
                let user = JSON.parse(localStorage.getItem("user"));
                //Allow users that are not SystemAdmins or users that are SystemAdmins but are logged in as customers
                if (user.role !== "SystemAdmin" || (user.role === "SystemAdmin" && localStorage.getItem("loggedCustomer"))) {
                    return true;
                } else {
                    this.router.navigate(['dashboard/admin']);
                    return false;
                }
            }),
            catchError(e => {
                //Get language from the localstorage before clearing it
                let language = localStorage.getItem("language");
                localStorage.clear();
                //Set language back to local storage after everything else has been cleared
                localStorage.setItem("language", language);
                this.cookies.deleteAll("/", undefined, false, "Lax");
                this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
                return of(false);
            }));
    }
}

@Injectable()
export class SSOAuthGuard  {

    constructor(
        private api: ApiService,
        private router: Router,
        private cookies: CookieService
    ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.api.isAuthSSO().pipe(
            map((res: Response) => {
                return true;
            }),
            catchError(e => {
                //Get language from the localstorage before clearing it
                let language = localStorage.getItem("language");
                localStorage.clear();
                //Set language back to local storage after everything else has been cleared
                localStorage.setItem("language", language);
                this.cookies.deleteAll("/", undefined, false, "Lax");
                this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
                return of(false);
            }));
    }
}

@Injectable()
export class LoginGuard  {
    constructor(
        private api: ApiService,
        private router: Router,
        private cookies: CookieService
    ) { }
    canActivate() {
        if (this.cookies.get("session-token")) {
            //If user is in landing page redirect them back there instead of dashboard
            if (localStorage.getItem("landing-page")) {
                this.router.navigate(['landing-page'])
                return true;
            }
            this.router.navigate(['dashboard']);
            return true;
        } else {
            return of(true);
        }
    }
}

@Injectable()
export class RoleGuard  {
    constructor(
        private api: ApiService,
        private router: Router,
        private cookies: CookieService
    ) { }
    canActivate(route: ActivatedRouteSnapshot) {
        let role = route.data.role;
        return this.api.isRole(role).pipe(
            map((res: Response) => {
                return true;
            }),
            catchError(e => {
                //Get language from the localstorage before clearing it
                let language = localStorage.getItem("language");
                localStorage.clear();
                //Set language back to local storage after everything else has been cleared
                localStorage.setItem("language", language);
                this.cookies.deleteAll("/", undefined, false, "Lax");
                this.router.navigate(['login']);
                return of(false);
            }));
    }
}

@Injectable()
export class SSORoleGuard  {
    constructor(
        private api: ApiService,
        private router: Router,
        private cookies: CookieService
    ) { }
    canActivate(route: ActivatedRouteSnapshot) {
        let role = route.data.role;
        return this.api.isSSORole(role).pipe(
            map((res: Response) => {
                return true;
            }),
            catchError(e => {
                this.router.navigate(['landing-page']);
                return of(false);
            }));
    }
}

@Injectable()
export class FeatureGuard  {
    constructor(
        private router: Router
    ) { }
    canActivate(route: ActivatedRouteSnapshot) {
        let feature = route.data.feature;
        let features: string[] = JSON.parse(localStorage.getItem("features"))
        if (features) {
            let index = features.indexOf(feature);
            if (index !== -1) {
                return of(true);
            }
        }
        this.router.navigate(['dashboard']);
        return of(false);
    }
}