<div class="container mt-4">
    <div class="row justify-content-between">
        <h3 class="float-left">Unloc key distribution</h3>
        <!-- Click this button to show manual key distribution (on separate page) -->
        <button class="btn btn-primary shadow float-right">Distribute keys manually</button>
    </div>
    <!-- Manual key distribution component: -->
    <!-- <unloc-key-distribution></unloc-key-distribution> -->

    <div class="row">
        <div class="col-12 card border-right-0 border-bottom-0 border-left-0 shadow-sm mt-4">
            <div class="card-body">
                <h5 class="card-title">Basic settings</h5>
                <p>Define rules for which Staff roles should have access to each type of lock. These rules can be
                    overridden through manual key distribution.</p>
                <div class="d-flex mt-2">
                    <p class="mt-4">Staff who are</p>
                    <mat-form-field appearance="outline" class="ml-3 mr-3">
                        <mat-label>Select a role</mat-label>
                        <mat-select>
                            <mat-option *ngFor="let role of roleTypes" value="role.value">{{role.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <p class="mt-4">have access to</p>
                    <mat-form-field appearance="outline" class="ml-3 mr-3">
                        <mat-label>Select a lock type</mat-label>
                        <mat-select multiple>
                            <mat-option *ngFor="let lock of lockTypes" value="{{lock.value}}">{{lock.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <button class="btn btn-link px-0 mb-1">+ Add a rule</button>
                </div>
                <div class="mt-3">
                    <button class="btn btn-primary shadow"> <span class="fa-light fa-floppy-disk mr-1"></span>
                        Save</button>
                </div>
            </div>
        </div>
    </div>
</div>