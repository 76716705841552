import { ApiService } from "app/services/api.service";
import { BehaviorSubject } from "rxjs";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ThrottleButtonClickDirective } from "app/directives/throttle-button-click.directive";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { Device } from "app/models/device.model";
import { CameraNamePipe } from "app/pipes/pipes";
import {
  DeviceNamePipe,
  ReceiverTeamNamePipe,
} from "../camera-configuration-pipes";
import {
  Component,
  Input,
  Output,
  OnChanges,
  EventEmitter,
} from "@angular/core";
import {
  AccessMode,
  NewCameraGroupForm,
  VideoServerCamera,
  VisitOnAlertConfig,
  VisitTimeOption,
} from "app/models/camera.model";
import { SelectCamerasComponent } from "../select-cameras/select-cameras.component";
import { IGroupedOption, IOption, MultiselectDropdownComponent } from "app/components/multiselect-dropdown/multiselect-dropdown.component";
import { ICamerasByGroup } from "../camera-configuration.component";

interface ISelectedLocation { id: string, name: string };

@Component({
  selector: "add-camera-group-form",
  templateUrl: "./camera-group-add.component.html",
  styleUrls: ["../camera-configuration.component.css"],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MultiselectDropdownComponent,
    MatSlideToggleModule,
    ThrottleButtonClickDirective,
    MatOptionModule,
    MatSelectModule,
    MatFormFieldModule,
    DeviceNamePipe,
    ReceiverTeamNamePipe,
    CameraNamePipe,
    SelectCamerasComponent
  ],
})
export class CameraGroupAddComponent implements OnChanges {
  constructor(private api: ApiService) { }

  @Input() showForm: BehaviorSubject<boolean>;

  // Select option data variables
  @Input() alertDeviceSelect: IOption[] = [];
  @Input() receiverTeamSelect: IGroupedOption[];
  @Input() locationSelect: { id: string; name: string }[] = [];
  @Input() cameraList: Device[];
  @Input() assignedCameras: ICamerasByGroup[];

  @Output() newCameraGroupAdded = new EventEmitter<string>();

  newCameraGroup: NewCameraGroupForm;
  visitConfig = new VisitOnAlertConfig();

  user: any;

  showCameraExistsErrorMsg: boolean = false;
  showSubmitErrorMsg: boolean = false;
  selectedAlertDevices: string[] = [];
  camerasOfLocation: IGroupedOption[];
  selectedLocation: ISelectedLocation;
  cameraSelectDisabled = new BehaviorSubject<boolean>(true);


  submit(): void {
    // Send visit configuration form to API only if access mode is 'visitOnAlert'
    const visitConfiguration =
      this.newCameraGroup.accessMode === AccessMode.VisitOnAlert
        ? this.visitConfig
        : null;

    this.showSubmitErrorMsg = false;

    this.api
      .addCameraGroup({
        cameraGroup: this.newCameraGroup,
        visitConfiguration: visitConfiguration,
      })
      .subscribe(
        () => {
          this.newCameraGroupAdded.emit("CAMERA_GROUP_ADDED_SUCCESS");
          this.showForm.next(false);
        },
        (error) => {
          this.showSubmitErrorMsg = true;
          console.log(error);
        }
      );
  }

  compareNames(a: any, b: any): number {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
  }

  onlocationSelectChange(): void {
    let location: ISelectedLocation | string = this.selectedLocation;

    if (typeof location === 'string' && location === 'undefined') {
      this.cameraSelectDisabled.next(true);
      this.newCameraGroup.locationId = null; // to disable receiver team select
      return;
    }

    // Empty the selected teams and cameras when selected location is changed
    this.newCameraGroup.userGroups = [];
    this.newCameraGroup.cameras = [];
    this.newCameraGroup.locationId = location.id;

    // Enable the camera control if a location is selected
    this.cameraSelectDisabled.next(false);

    // Select the cameras for this location
    this.camerasOfLocation = [...this.selectCamerasOfThisLocation(location.id, this.camerasOfLocation)];
    this.receiverTeamSelect = [...this.disableReceiverTeamLocationSelects(this.receiverTeamSelect)];
  }

  onCameraSelectChange(cameras: VideoServerCamera[]): void {
    this.newCameraGroup.cameras = cameras;
  }

  shouldDisableCameras(options: IOption[]): void {
    options.forEach(option => option.disabled = this.isCameraAlreadyAssigned(option.value));
  }

  selectCamerasOfThisLocation(id: string, cameras: IGroupedOption[]): IGroupedOption[] {
    for (const location of cameras) {
      id.startsWith("C02") || location.id === id
        ? location.disabled = false
        : location.disabled = true;

      this.shouldDisableCameras(location.options);
    }

    return cameras;
  }

  initCameraSelect(): void {
    this.camerasOfLocation = [];

    for (const location of this.locationSelect) {
      const option: IGroupedOption = {
        label: location.name,
        id: location.id,
        disabled: true,
        options: this.cameraList
          .filter((camera) => camera.locationId === location.id)
          .map((camera) => {
            return {
              name: `${camera.name} (${camera.id})`,
              value: camera.id,
              disabled: false,
            }
          })
      }
      this.camerasOfLocation.push(option);
    }
    this.camerasOfLocation = this.camerasOfLocation.filter(group => group.options.length > 0);
  }

  disableReceiverTeamLocationSelects(locations: IGroupedOption[]): IGroupedOption[] {
    const customerId = JSON.parse(localStorage.getItem("customerInfo")).customerId;

    // All receiver teams should be selectable, if selected location is a customer (top-level)
    if (this.newCameraGroup.locationId === customerId) {
      locations.forEach(location => location.disabled = false);
    } else {

      for (let location of locations) {
        // If the location is not a customer, receiver groups from selected location and top-level location should be available
        if (
          location.id === this.newCameraGroup.locationId ||
          location.id === customerId
        ) {
          location.disabled = false;
        } else {
          location.disabled = true;
        }
      }
    }

    return locations;
  }

  parseSelectedDevices(deviceIds: string[]): void {
    const array: { deviceID: string }[] = [];

    for (let deviceId of deviceIds) {
      array.push({ deviceID: deviceId });
    }
    this.visitConfig.alertSources = array;
  }

  setCount(count: number, option: VisitTimeOption): void {
    switch (option) {
      case VisitTimeOption.visitTimeMinutes:
        this.visitConfig.options.visitTimeMinutes =
          this.visitConfig.options.visitTimeMinutes + count >= 0 ? count : 0;
        break;
      case VisitTimeOption.visitGraceTimeMinutes:
        this.visitConfig.options.visitGraceTimeMinutes =
          this.visitConfig.options.visitGraceTimeMinutes + count >= 0
            ? count
            : 0;
        break;
      default:
        break;
    }
  }

  validateVisitTimeInput(value: string, option: VisitTimeOption): void {
    // Check if the input is a number
    const isNumber = !isNaN(parseInt(value));
    switch (option) {
      case VisitTimeOption.visitTimeMinutes:
        if (!isNumber) this.visitConfig.options.visitTimeMinutes = 0;
        break;
      case VisitTimeOption.visitGraceTimeMinutes:
        if (!isNumber) this.visitConfig.options.visitGraceTimeMinutes = 0;
        break;
      default:
        break;
    }
  }

  ngOnChanges(): void {
    this.user = JSON.parse(localStorage.getItem("user"));

    this.newCameraGroup = new NewCameraGroupForm(this.user);
    this.initCameraSelect();
  }

  private isCameraAlreadyAssigned(cameraId: string): boolean {
    return this.assignedCameras.some(group => group.cameraIds.includes(cameraId));
  }
}
