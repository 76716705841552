<div
    class="container d-flex align-datas-center justify-content-center"
    style="width: 80%">
    <div style="width: 100%" class="mb-3">
        <h3 class="mb-5">{{ "CAMERA_CONFIGURATION" | translate }}</h3>

        <div class="mb-3">
            <button class="btn btn-primary" (click)="showNewGroupForm.next(true)" [class.restrictor]="user.roleLevel < 101">
                <span class="fa-light fa-fw fa-plus"></span>
                {{ "NEW_CAMERA_GROUP" | translate }}
            </button>

            <input
              type="text"
              class="form-control border border-secondary float-right"
              style="width: 30%"
              placeholder="{{ 'SEARCH' | translate }}..."
              #searchInput
            />
        </div>

        <add-camera-group-form
            *ngIf="showNewGroupForm.value"
            [showForm]="showNewGroupForm"
            [alertDeviceSelect]="alertDeviceSelect"
            [receiverTeamSelect]="receiverTeamSelect"
            [locationSelect]="locationSelect"
            [cameraList]="cameraList"
            [assignedCameras]="assignedCameras"
            (newCameraGroupAdded)="refreshCameraGroups($event)">
        </add-camera-group-form>

        <camera-group-edit
            *ngIf="showEditForm.value"
            [showForm]="showEditForm"
            [originalCameraGroup]="cameraGroupDataToEdit.cameraGroup"
            [originalVisitConfig]="cameraGroupDataToEdit.visitConfig"
            [alertDeviceSelect]="alertDeviceSelect"
            [receiverTeamSelect]="receiverTeamSelect"
            [locationSelect]="locationSelect"
            [cameraList]="cameraList"
            [assignedCameras]="assignedCameras"
            (cameraGroupChanged)="refreshCameraGroups($event)">
        </camera-group-edit>

        <div *ngIf="successMsg" class="alert alert-success" role="alert" [@fadeOut]>
            {{ successMsg | translate }}
        </div>

        <div *ngIf="warningMsg" class="alert alert-danger" role="alert" [@fadeOut]>
            {{ warningMsg | translate }}
        </div>

        <div class="card">
            <div
              class="card-header-white"
              style="border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important"
            >
                <h6 class="white-header-text">
                    {{ "CAMERA_GROUPS" | translate }}
                </h6>
            </div>

            <mat-accordion displayMode="flat" multi class="mat-table">
                <ng-container *ngIf="cameraGroups && pageDataLoaded">
                    <mat-expansion-panel
                      *ngFor="let data of filteredCameraGroups; let i = index"
                      [expanded]="data?.panelExpanded"
                      [ngStyle]="{ 'border-top': i > 0 ? '1px solid rgba(0, 0, 0, .1)' : 'none'}"
                      #panel
                    >
                        <mat-expansion-panel-header>
                            <mat-panel-title
                                innerHTML="{{ data.cameraGroup.displayName | highlight : search}}">
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div *ngIf="panel.expanded" class="pb-4">
                            <div class="row pb-3">
                                <div class="col">
                                    <div class="infoRow mb-1" *ngIf="data.cameraGroup.displayName">
                                        <strong>{{ "GROUP_NAME" | translate}}:</strong>&nbsp;
                                        <p innerHTML="{{ data.cameraGroup.displayName | highlight : search }}"></p>
                                    </div>

                                    <div class="infoRow mb-1" *ngIf="data.cameraGroup.address || data.cameraGroup.postCode || data.cameraGroup.postOffice">
                                        <strong>{{ "ADDRESS" | translate }}:</strong>&nbsp;
                                        <p innerHTML="{{ data.cameraGroup | cameraGroupFullAddress | highlight : search }}"></p>
                                    </div>

                                    <div class="infoRow mb-1" *ngIf="data.cameraGroup.locationId">
                                        <strong>{{ "LOCATION" | translate }}:</strong>&nbsp;
                                        <p innerHTML="{{ data.cameraGroup.locationId | locationName : locationSelect| highlight : search }}"></p>
                                    </div>

                                    <div class="infoRow mb-1" *ngIf="data.cameraGroup.timeZone">
                                        <strong>{{ "TIME_ZONE" | translate }}:</strong>&nbsp;
                                        <p innerHTML="{{ data.cameraGroup.timeZone | highlight : search }}"></p>
                                    </div>

                                    <div class="infoRow">
                                        <strong>{{ "ACCESS_MODE" | translate }}:</strong>&nbsp;
                                        <p innerHTML="{{ data.cameraGroup.accessMode | accessModeLangCode | translate | highlight : search }}"></p>
                                    </div>
                                </div>

                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <button
                                                class="btn btn-outline-dark float-right"
                                                (click)="setEditGroup(data)"
                                                    *ngIf="user.roleLevel > 100"
                                                    >
                                                <span class="fa fa-edit"></span>{{ "MODIFY" | translate }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="data.visitConfig || data.cameraGroup.userGroups">
                                <hr />
                                <div class="row pt-1">
                                    <div
                                      class="col"
                                      *ngIf="data.cameraGroup.accessMode === 'visitOnAlert' && data.visitConfig"
                                      [ngClass]="{ rightBorder: data.cameraGroup.userGroups }"
                                    >
                                        <h6 class="pb-1">
                                            <strong>
                                                {{ "VISIT_CONFIGURATION" | translate }}
                                            </strong>
                                        </h6>

                                        <div class="topBorder">
                                            <div class="pt-3 pb-4 mt-1">
                                                <div class="row pl-4">
                                                    <strong style="color: #1d83e9">
                                                        {{ "VISIT_TIME_MINUTES" | translate }}:
                                                    </strong>

                                                    <b class="ml-1"
                                                        innerHTML="{{ data.visitConfig.options.visitTimeMinutes.toString() | highlight: search }}">
                                                    </b>

                                                    <strong class="ml-5" style="color: #1d83e9">
                                                        {{ "VISIT_GRACE_TIME_MINUTES" | translate }}:
                                                    </strong>

                                                    <b class="ml-1"
                                                        innerHTML="{{ data.visitConfig.options.visitGraceTimeMinutes.toString() | highlight : search }}">
                                                    </b>
                                                </div>
                                            </div>

                                            <div class="mt-2">
                                                <strong class="ml-2">{{ "ALERT_SOURCES" | translate }}</strong>

                                                <div class="topBorder mt-2 pt-1 pl-2">
                                                    <li *ngFor="let device of data.visitConfig.alertSources"
                                                        innerHTML="{{ device.deviceID | deviceName : alertDeviceSelect | highlight : search }}">
                                                    </li>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col" *ngIf="data.cameraGroup.userGroups && data.cameraGroup.userGroups.length > 0">
                                        <h6 class="pb-1 ml-3">
                                            <strong>
                                                {{ "RECEIVER_TEAMS" | translate }}
                                            </strong>
                                        </h6>

                                        <div class="col-sm-12 topBorder pt-1 pl-4">
                                            <li *ngFor="let groupId of data.cameraGroup.userGroups"
                                                innerHTML="{{ groupId | receiverTeamName : receiverTeamSelect | highlight : search }}">
                                            </li>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="data?.cameraGroup?.cameras?.length > 0">
                                <hr class="pt-3" />
                                <h6 style="margin-bottom: 10px">
                                    <strong>{{ "CAMERAS" | translate }}</strong>
                                </h6>

                                <div *ngFor="let camera of data.cameraGroup.cameras" class="bordered-box w-50 py-2 mb-3">
                                    <p class="cameraName">
                                        {{ camera.displayName || "-" }} <span *ngIf="camera.discoveryName">({{ camera.discoveryName }})</span>
                                    </p>

                                    <label class="custom-checkbox">
                                        <input
                                            type="checkbox"
                                            name="motion-detection"
                                            [checked]="camera.motionDetection.enabled"
                                            [disabled]="!camera.motionDetection.enabled"
                                            (click)="preventCheckboxClick($event)"
                                        />
                                        {{ "MOTION_DETECTION_IN_CAMERA" | translate }}
                                    </label>

                                    <label class="custom-checkbox pl-3">
                                        <input
                                            type="checkbox"
                                            name="snapshot"
                                            [disabled]="!camera.motionDetection.snapshot"
                                            [checked]="camera.motionDetection.snapshot"
                                            (click)="preventCheckboxClick($event)"
                                        >
                                        {{ "SNAPSHOT_WITH_ALERT" | translate }}
                                    </label>

                                    <label class="custom-checkbox pl-3">
                                        <input
                                            type="checkbox"
                                            name="videoclip"
                                            [disabled]="!camera.motionDetection.videoclip"
                                            [checked]="camera.motionDetection.videoclip"
                                            (click)="preventCheckboxClick($event)"
                                        />
                                        {{ "VIDEOCLIP_WITH_ALERT" | translate }}
                                    </label>

                                    <ng-container *ngIf="'AnonymizedCameraSupervision' | isFeatureEnabled">
                                        <label class="custom-checkbox">
                                            <input
                                                type="checkbox"
                                                name="anonymized-visit"
                                                [checked]="camera.anonymization"
                                                [disabled]="!camera.anonymization"
                                                (click)="preventCheckboxClick($event)"
                                            />
                                            <span>
                                                <i class="fa-solid fa-eye-slash"></i>
                                                {{ "ANONYMIZED_VISIT" | translate }}
                                            </span>
                                        </label>

                                        <label class="custom-checkbox pl-3">
                                            <input
                                                type="checkbox"
                                                name="clear-img-toggle"
                                                [disabled]="!camera.toggleAnonymization"
                                                [checked]="camera.toggleAnonymization"
                                                (click)="preventCheckboxClick($event)"
                                            />
                                            <span>
                                                <i class="fa-solid fa-eye"></i>
                                                {{ "TOGGLE_CLEAR_IMAGE" | translate }}
                                            </span>
                                        </label>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </mat-expansion-panel>
                </ng-container>

                <div *ngIf="!pageDataLoaded" class="loadingIconContainer">
                    <img class="fadeLoader logoSmall mx-auto d-block" src="assets/img/loading_logo.svg" />
                </div>

                <div *ngIf="pageDataLoaded && !cameraGroups">
                    <p class="mt-3 ml-3">
                        {{ "NO_CAMERA_GROUPS" | translate }}
                    </p>
                </div>
            </mat-accordion>
        </div>
    </div>
</div>
