<div id="container">
    <ng-container *ngIf="initDataLoaded; else elseBlock">
        <div id="headerRow">
            <h5>
                {{
                    (data.title | translate) +
                        (editMode ? " (" + originalCardName + ")" : "")
                }}
            </h5>
            <i
                class="fa-sharp fa-solid fa-x align-self-start m-0"
                (click)="closeForm()"
            ></i>
        </div>

        <ng-container *ngIf="initErrorMsg">
            <div class="alert alert-danger" id="initErrorMsg" role="alert">
                {{ initErrorMsg }}
            </div>
        </ng-container>

        <form role="form" #saveConfig="ngForm">
            <div class="form-group">
                <label>{{ "NAME" | translate }}</label>
                <input
                    type="text"
                    class="form-control form-control-sm"
                    name="name"
                    #name="ngModel"
                    [(ngModel)]="card.name"
                    [ngClass]="!name.value ? 'is-invalid' : 'is-valid'"
                    [disabled]="saveDisabled"
                    required
                />
                <span class="text-danger" *ngIf="!name.value">
                    {{ "REQUIRED_FIELD" | translate }}
                </span>
            </div>

            <div class="form-group pt-3">
                <label>{{ "INCLUDE_DEVICES" | translate }}:</label>
                <div id="deviceSelect">
                    <ngx-bootstrap-multiselect
                        class="careRecipientCardFormDropdownMenu"
                        [settings]="deviceSelectSettings"
                        [options]="deviceSelectOptions"
                        [texts]="deviceSelectTexts"
                        [(ngModel)]="card.devices"
                        [disabled]="saveDisabled"
                        name="deviceSelect"
                    >
                    </ngx-bootstrap-multiselect>
                </div>
            </div>

            <p id="configLabel">{{ "SHOW_ON_CARD" | translate }}:</p>

            <div class="switchContainer">
                <label class="switch">
                    <input
                        type="checkbox"
                        name="showAlerts"
                        [(ngModel)]="card.showAlerts"
                        [disabled]="saveDisabled"
                    />
                    <span class="slider"></span>
                </label>
                <p class="switchLabel">
                    {{ "NUMBER_OF_ALERTS" | translate }}
                </p>
            </div>

            <div class="switchContainer">
                <label class="switch">
                    <input
                        type="checkbox"
                        name="showAverageWaitingTime"
                        [(ngModel)]="card.showAvgWaitTime"
                        [disabled]="saveDisabled"
                    />
                    <span class="slider"></span>
                </label>
                <p class="switchLabel">{{ "AVG_WAITING_TIME" | translate }}</p>
            </div>

            <div class="switchContainer">
                <label class="switch">
                    <input
                        type="checkbox"
                        name="showAverageCareTime"
                        [(ngModel)]="card.showAvgCareTime"
                        [disabled]="saveDisabled"
                    />
                    <span class="slider"></span>
                </label>
                <p class="switchLabel">
                    {{ "AVG_CARE_TIME" | translate }}
                </p>
            </div>

            <div class="switchContainer">
                <label class="switch">
                    <input
                        type="checkbox"
                        name="showAlertReasons"
                        [(ngModel)]="card.showAlertReasons"
                        [disabled]="saveDisabled"
                    />
                    <span class="slider"></span>
                </label>
                <p class="switchLabel">
                    {{ "MOST_COMMON_ALERT_REASONS" | translate }}
                </p>
            </div>

            <div class="d-flex justify-content-end" *ngIf="errorMsg">
                <div class="alert alert-danger" id="saveErrorMsg" role="alert">
                    {{ saveErrorMsg | translate }}
                </div>
            </div>

            <div class="d-flex mt-3">
                <button
                    type="cancel"
                    class="btn secondaryBtnWhiteBg ml-auto"
                    (click)="closeForm()"
                >
                    {{ "CANCEL" | translate }}
                </button>
                <button
                    type="submit"
                    id="saveBtn"
                    class="btn btn-primary ml-2"
                    [disabled]="!saveConfig.form.valid || saveDisabled"
                    appThrottleClick
                    (throttledClick)="saveCard()"
                >
                    <i
                        [ngClass]="
                            saving
                                ? 'fad fa-spinner-third fa-spin'
                                : 'fa-light fa-floppy-disk'
                        "
                    ></i>
                    {{ "SAVE_AND_CLOSE" | translate }}
                </button>
            </div>
        </form>
    </ng-container>

    <ng-template #elseBlock>
        <img
            class="fadeLoader centerLoader logoSmall"
            src="assets/img/loading_logo.svg"
        />
    </ng-template>
</div>
