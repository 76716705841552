import * as moment from "moment";

export class StatisticsFilters {
  locations: string[] = [];
  deviceTypes: string[] = [];
  alertTypes: string[] = [];
  devices: string[] = [];
  alertRoutes: string[] = [];
  receiverTeams: string[] = [];
  personnels: string[] = [];
  startDate: Date = moment().subtract(7, "d").startOf("d").utc().toDate();
  endDate: Date = moment().endOf("d").utc().toDate();

  setDates(option: StatisticsDateOption): void {
    switch (option) {
      case StatisticsDateOption.Today:
        this.startDate = moment().startOf("d").utc().toDate();
        this.endDate = moment().endOf("d").utc().toDate();
        break;
      case StatisticsDateOption.YesterDay:
        this.startDate = moment().subtract(1, "d").startOf("d").utc().toDate();
        this.endDate = moment().subtract(1, "d").endOf("d").utc().toDate();
        break;
      case StatisticsDateOption.LastWeek:
        const fDayOfLastWeek = moment()
          .subtract(1, "week")
          .startOf("week")
          .startOf("d");
        const lDayOfLastWeek = fDayOfLastWeek.clone().endOf("week").endOf("d");

        this.startDate = fDayOfLastWeek.utc().toDate();
        this.endDate = lDayOfLastWeek.utc().toDate();
        break;
      case StatisticsDateOption.LastMonth:
        const fDayOfMonth = moment()
          .subtract(1, "month")
          .startOf("month")
          .startOf("d");
        const lDayOfMonth = fDayOfMonth.clone().endOf("month").endOf("d");

        this.startDate = fDayOfMonth.utc().toDate();
        this.endDate = lDayOfMonth.utc().toDate();
        break;
      case StatisticsDateOption.Last90Days:
        this.startDate = moment().subtract(90, "d").startOf("d").utc().toDate();
        this.endDate = moment().endOf("d").utc().toDate();
        break;
      case StatisticsDateOption.Custom:
        this.startDate = moment().subtract(1, "d").startOf("d").utc().toDate();
        this.endDate = moment().endOf("d").utc().toDate();
        break;
    }
  }
}

export enum StatisticsDateOption {
  Today = "today",
  YesterDay = "yesterday",
  LastWeek = "lastWeek",
  LastMonth = "lastMonth",
  Last90Days = "last90Days",
  Custom = "custom",
}

/**
 * Care recipient statistics
 */
export class CareRecipientStatistics {
  data: CareRecipientStatisticsData;
  card: CareRecipientCard;
}

export class CareRecipientStatisticsData {
  totalAlerts: number;
  avgWaitTimeSeconds: number; // Time from start of alert until nurse starts task
  avgCareTimeSeconds: number; // Time from nurse starts task until nurse finishes task
  totalAlarmReasons: number; // Total number of alarm reasons included to alerts
  alertCountByAlarmReasons: {
    key: string;
    alarmCount: number; // Alarm count
  }[];
}

export class CareRecipientCard {
  constructor(user: any) {
    this.userId = user.userId;
    this.customerId = user.customerId;
  }
  readonly id: string; // Cards ID
  readonly userId: string;
  readonly customerId: string;
  name: string = "";
  notes: ICareRecipientCardNote[] = [];
  devices: string[] = [];
  showAlerts: boolean = false;
  showAvgWaitTime: boolean = false;
  showAvgCareTime: boolean = false;
  showAlertReasons: boolean = false;
}

export interface ICareRecipientCardNote {
  comment: string;
  commentTime: Date;
}

export interface ICareRecipientCardUserLimit {
  available: boolean;
  maximumLimit: number;
}

/**
 * Emaac statistics data
 */
export interface IEmaacStatistics {
  alertsByAlarmReasons: IAlertsByAlarmReason[];
  alertsByDay: IDeviceTypeAlertsByWeekday[];
  alertsByHour: IDeviceTypeAlertsByHour[];
  responseTimes: IEmaacResponseTimes;
  careRecipientCount: number;
  emaacAlertStats: IEmaacAlertStatistics;
}

export interface IEmaacAlertStatistics {
  totalAlerts: number;
  emaAlerts: number;
  /** Count of alerts that are finished by alarm center */
  finishedAlerts: number;
}

export interface IEmaacResponseTimes {
  resTimeStats: IResponseTimeStatistics;
  alertResponses: IAlertResponseTime[];
}

export interface IAlertResponseTime {
  timestamp: string;
  deviceName: string;
  deviceType: string;
  deviceId: string;
  responseTime: number;
}

export interface IResponseTimeStatistics {
  avgResTimeSeconds: number;
  medianResTimeSeconds: number;
}

export interface IAlertsByAlarmReason {
  alarmReasonCategory: string;
  totalAlerts: number;
  alarmReasonAlerts: { alarmReason: string; alertCount: number }[];
}

/**
 * Alert statistics data
 */
export interface IAlertStatistics {
  locationAlerts: ILocationAlerts;
  deviceAlerts: IDeviceAlert[];
  alertsByDays: IDeviceTypeAlertsByWeekday[];
  alertsByHours: IDeviceTypeAlertsByHour[];
  alertsByTypes: IAlertsByAlertType[];
  averageDurations: IAverageAlertDurations;
}

export interface IDeviceAlert {
  deviceName: string;
  deviceType: string;
  deviceId: string;
  locationName: string;
  address: string;
  postalCode: string;
  city: string;
  moreInfo: string;
  alertCount: number;
}

export interface IAverageAlertDurations {
  avgTechDurationSeconds: number;
  avgQueueTimeSeconds: number;
  avgReservedTimeSeconds: number;
  avgCareTimeSeconds: number;
}

export interface ILocationAlerts {
  totalAlerts: number;
  alertsByLocations: IAlertsByLocation[];
}

export interface IAlertsByLocation {
  locationId: string;
  locationName: string;
  alertCount: number;
}

export interface IDeviceTypeAlertsByWeekday {
  /** Number of alerts per day of the week from monday to sunday */
  alertsPerDay: number[];
  deviceType: string;
}

export interface IDeviceTypeAlertsByHour {
  alertsPerHour: number[];
  deviceType: string;
}

export interface IAlertsByAlertType {
  deviceType: string;
  activations: IAlertsByActivation[];
  totalAlerts: number;
}

export interface IAlertsByActivation {
  group: number;
  node: number;
  alertCount: number;
}
