<ng-container *ngIf="statistics.careRecipientTabResultMsg">
    <div class="alert alert-success" role="alert" id="messageBoard" [@fadeOut]>
        {{ statistics.careRecipientTabResultMsg | translate }}
    </div>
</ng-container>

<ng-container *ngIf="statistics.careRecipientCardsLoaded; else loadIconBlock">
    <div cdkDropListGroup>
        <ng-container
            *ngFor="let row of cardRows; let i = index; let last = last"
        >
            <div
                class="cardRow"
                cdkDropList
                cdkDropListOrientation="horizontal"
                [cdkDropListData]="{ rowIndex: i, item: row }"
                (cdkDropListDropped)="onCardDrop($event)"
                [ngClass]="{ lastRow: !cardsCountEvenToThree && last }"
            >
                <care-recipient-card
                    *ngFor="let card of row; let i = index"
                    [careRecipientStats]="card"
                    [translations]="translations"
                    [longestAvgWaitTime]="longestAvgWaitTime"
                    [longestAvgCareTime]="longestAvgCareTime"
                    (cdkDragStarted)="isCardDragged = true"
                    (cdkDragEnded)="isCardDragged = false"
                    cdkDrag
                ></care-recipient-card>

                <div
                    *ngIf="!cardsCountEvenToThree && last"
                    class="newCareRecipient"
                    style="margin-left: 15px"
                    (click)="openNewCareRecipientCardDialog()"
                >
                    <h5>+ {{ "ADD_NEW" | translate }}</h5>
                </div>
            </div>
        </ng-container>

        <div *ngIf="cardsCountEvenToThree" class="cardRow lastRow">
            <div
                class="newCareRecipient"
                (click)="openNewCareRecipientCardDialog()"
            >
                <h5>+ {{ "ADD_NEW" | translate }}</h5>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #loadIconBlock>
    <img
        class="fadeLoader centerLoader logoSmall"
        src="assets/img/loading_logo.svg"
    />
</ng-template>
