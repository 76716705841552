import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { GlobalVariables } from '../services/helper.service'
import { forkJoin, Observable, PartialObserver } from 'rxjs';
import { environment } from '../../environments/environment';
import * as io from 'socket.io-client';
import { OnOnProfile } from 'app/models/onon.model';


@Injectable()
export class OnOnService {
    constructor(
        private cookies: CookieService,
        private router: Router,
        private globals: GlobalVariables,
        backend: HttpBackend
    ) {
        this.http = new HttpClient(backend);
    };

    private http: HttpClient
    private baseUrl = environment.onOnBaseUrl;
    private socket;


    login(): Observable<any> {
        let url = this.baseUrl + '/api/login';
        let options = {
            headers: new HttpHeaders({ Authorization: this.cookies.get("session-token") })
        }
        return this.http.post(url, null, options);
    }

    initializeOnOnData(): Observable<any[]> {
        return forkJoin([this.getCameras(), this.getCameraGroups(), this.getCustomerProfiles(), this.getActiveCameraGroupProfiles()])
    }

    getCameraGroups(): Observable<any> {
        let url = this.baseUrl + '/api/cameragroups';
        let options = {
            headers: new HttpHeaders({ Authorization: this.cookies.get("onon-session-token"), 'Cache-Control': 'no-cache', Pragma: 'no-cache' })
        }
        return this.http.get(url, options);
    }

    getCameraGroupDetails(id: string): Observable<any> {
        let url = this.baseUrl + '/api/cameragroups/' + id;
        let options = {
            headers: new HttpHeaders({ Authorization: this.cookies.get("onon-session-token"), 'Cache-Control': 'no-cache', Pragma: 'no-cache' })
        }
        return this.http.get(url, options);
    }

    getCameras(): Observable<any> {
        let url = this.baseUrl + '/api/cameras';
        let options = {
            headers: new HttpHeaders({ Authorization: this.cookies.get("onon-session-token"), 'Cache-Control': 'no-cache', Pragma: 'no-cache' })
        }
        return this.http.get(url, options);
    }

    getActiveCameraGroupProfiles(): Observable<any> {
        let url = this.baseUrl + '/api/cameragroups/profilesets';
        let options = {
            headers: new HttpHeaders({ Authorization: this.cookies.get("onon-session-token"), 'Cache-Control': 'no-cache', Pragma: 'no-cache' })
        }
        return this.http.get(url, options);
    }

    changeActiveCameraGroupProfile(data: any) {
        let url = this.baseUrl + `/api/cameragroups/${data.cameraGroupId}/profileset`;
        let options = {
            headers: new HttpHeaders({ Authorization: this.cookies.get("onon-session-token"), 'Cache-Control': 'no-cache', Pragma: 'no-cache' })
        }
        return this.http.put(url, data, options)
    }

    getCustomerProfiles(): Observable<any> {
        let url = this.baseUrl + '/api/profiles';
        let options = {
            headers: new HttpHeaders({ Authorization: this.cookies.get("onon-session-token"), 'Cache-Control': 'no-cache', Pragma: 'no-cache' })
        }
        return this.http.get(url, options);
    }

    getCameraGroupStates(): Observable<any> {
        let url = this.baseUrl + '/api/cameragroups/supervisionstate';
        let options = {
            headers: new HttpHeaders({ Authorization: this.cookies.get("onon-session-token"), 'Cache-Control': 'no-cache', Pragma: 'no-cache' })
        }
        return this.http.get(url, options);
    }

    updateProfile(profile: OnOnProfile): Observable<any> {
        let url = this.baseUrl + '/api/profiles/' + profile.id;
        let options = {
            headers: new HttpHeaders({ Authorization: this.cookies.get("onon-session-token"), 'Cache-Control': 'no-cache', Pragma: 'no-cache' })
        }
        return this.http.put(url, profile, options)
    }

    insertNewProfile(profile: OnOnProfile): Observable<any> {
        let url = this.baseUrl + '/api/profiles';
        let options = {
            headers: new HttpHeaders({ Authorization: this.cookies.get("onon-session-token"), 'Cache-Control': 'no-cache', Pragma: 'no-cache' })
        }
        return this.http.post(url, profile, options)
    }

    deleteProfile(id: string): Observable<any> {
        let url = this.baseUrl + '/api/profiles/' + id;
        let options = {
            headers: new HttpHeaders({ Authorization: this.cookies.get("onon-session-token"), 'Cache-Control': 'no-cache', Pragma: 'no-cache' })
        }
        return this.http.delete(url, options)
    }

    getZonesForCamera(id: string): Observable<any> {
        let url = this.baseUrl + '/api/cameras/' + id + '/instruments';
        let options = {
            headers: new HttpHeaders({ Authorization: this.cookies.get("onon-session-token"), 'Cache-Control': 'no-cache', Pragma: 'no-cache' })
        }
        return this.http.get(url, options)
    }

    addZoneForCamera(id: string, data: any): Observable<any> {
        let url = this.baseUrl + '/api/cameras/' + id + '/instruments';
        let options = {
            headers: new HttpHeaders({ Authorization: this.cookies.get("onon-session-token"), 'Cache-Control': 'no-cache', Pragma: 'no-cache' })
        }
        return this.http.post(url, data, options)
    }

    deleteZoneForCamera(cameraId: string, zoneId: string): Observable<any> {
        let url = this.baseUrl + '/api/cameras/' + cameraId + '/instruments/' + zoneId;
        let options = {
            headers: new HttpHeaders({ Authorization: this.cookies.get("onon-session-token"), 'Cache-Control': 'no-cache', Pragma: 'no-cache' })
        }
        return this.http.delete(url, options)
    }

    updateZoneForCamera(cameraId: string, zone: any): Observable<any> {
        let url = this.baseUrl + '/api/cameras/' + cameraId + '/instruments/' + zone.id;
        let options = {
            headers: new HttpHeaders({ Authorization: this.cookies.get("onon-session-token"), 'Cache-Control': 'no-cache', Pragma: 'no-cache' })
        }
        return this.http.put(url, zone, options)
    }


    getCameraSnapshot(id: string): Observable<Blob> {
        let url = this.baseUrl + '/api/cameras/' + id + '/images/latest';
        return this.http.get(url, {
            headers: new HttpHeaders({ Authorization: this.cookies.get("onon-session-token"), 'Cache-Control': 'no-cache', Pragma: 'no-cache' }),
            responseType: 'blob'
        })
    }

    getAlertBackgroundImage(id: string): Observable<any> {
        let url = this.baseUrl + '/api/cameras/' + id + '/images/background';
        return this.http.get(url, {
            headers: new HttpHeaders({ Authorization: this.cookies.get("onon-session-token"), 'Cache-Control': 'no-cache', Pragma: 'no-cache' }),
            responseType: 'blob'
        })
    }

    setAlertBackgroundImage(id: string): Observable<any> {
        let url = this.baseUrl + '/api/cameras/' + id + '/images/background';
        let options = {
            headers: new HttpHeaders({ Authorization: this.cookies.get("onon-session-token"), 'Cache-Control': 'no-cache', Pragma: 'no-cache' })
        }
        return this.http.put(url, null, options)
    }

    deleteAlertBackgroundImage(id: string): Observable<any> {
        let url = this.baseUrl + '/api/cameras/' + id + '/images/background';
        let options = {
            headers: new HttpHeaders({ Authorization: this.cookies.get("onon-session-token"), 'Cache-Control': 'no-cache', Pragma: 'no-cache' })
        }
        return this.http.delete(url, options)
    }


    initSocket(): void {
        //Remove Bearer from the token which is used for API calls
        let token = this.cookies.get("onon-session-token").substring(7);
        this.socket = io(`${this.baseUrl}?token=${token}`);
    }

    closeSocket(): void {
        if (this.socket) {
            this.socket.disconnect();
        }
    }

    socketOnMessage(event: string): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on(event, (data: any) => observer.next(data));
        })
    }
}
