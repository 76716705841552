import { NativeDateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { Inject, Injectable, Optional } from '@angular/core';

@Injectable()
export class MyDateAdapter extends NativeDateAdapter {

  constructor(@Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string) {
    super(matDateLocale);
  }

  // Override the parse method
  parse(value: string): Date | null {
    if (typeof value === 'string' && value.length > 0) {
      const parts = value.split(/[\/\.\-]/); // Split by /, . or -
      if (parts.length === 3) {
        const [day, month, year] = this.getDateParts(parts);
        return new Date(year, month, day, 12); // Set time to noon to avoid DST issues
      }
    }
    return null;
  }

  // Override the format method
  format(date: Date, displayFormat: Object): string {
    if (!date) return '';

    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Month is 0-based
    const year = date.getFullYear();

    // Format based on locale
    switch (this.locale) {
      case 'fi':
      case 'ee':
        // DD.MM.YYYY
        return `${day}.${month}.${year}`;
      case 'sv':
        // YYYY-MM-DD
        return `${year}-${month}-${day}`;
      case 'en-GB':
        // DD/MM/YYYY
        return `${day}/${month}/${year}`;
      default:
        // Default format
        return `${day}/${month}/${year}`;
    }
  }

  private getDateParts(parts: string[]): [day: number, month: number, year: number] {
    switch (this.locale) {
      case 'fi':
      case 'ee':
      case 'en-GB':
        return [+parts[0], +parts[1] - 1, +parts[2]]; // Month is 0-based
      case 'sv':
        return [+parts[2], +parts[1] - 1, +parts[0]]; // Month is 0-based
      default:
        throw new Error(`Unsupported locale: ${this.locale}`);
    }
  }
}
