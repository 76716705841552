import { switchMap } from "rxjs/operators";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import {
  StatisticsFilters,
  IAlertStatistics,
  IEmaacStatistics,
  CareRecipientCard,
  CareRecipientStatistics,
  ICareRecipientCardUserLimit,
  ICareRecipientCardNote,
} from "app/models/statistics.model";

@Injectable({
  providedIn: "root",
})
export class StatisticsService {
  constructor(private api: ApiService) {
    this.alertStatistics$ = this.fetchAlertStatistics$.pipe(
      switchMap(() => this.fetchAlertStatistics())
    );
    this.emaacStatistics$ = this.fetchEmaacStatistics$.pipe(
      switchMap(() => this.fetchEmaacStatistics())
    );
    this.careRecipientCards$ = this.fetchCareRecipientCards$.pipe(
      switchMap(() => this.fetchCareRecipientCards())
    );
  }

  filters: StatisticsFilters = new StatisticsFilters();

  fetchAlertStatistics$ = new Subject<IAlertStatistics>();
  fetchEmaacStatistics$ = new Subject<IEmaacStatistics>();
  fetchCareRecipientCards$ = new Subject<CareRecipientStatistics[][]>();

  alertStatistics$: Observable<IAlertStatistics>;
  emaacStatistics$: Observable<IEmaacStatistics>;
  careRecipientCards$: Observable<CareRecipientStatistics[][]>;

  removedCareRecipientCardId$ = new BehaviorSubject<string>("");

  alertsTabDataLoaded: boolean = false;
  alarmCenterTabDataLoaded: boolean = false;
  careRecipientCardsLoaded: boolean = false;

  careRecipientTabResultMsg: string = "";

  getStatisticsTabData(tab: "alerts" | "alarmCenter" | "careRecipients"): void {
    switch (tab) {
      case "alerts":
        this.fetchAlertStatistics$.next();
        break;
      case "alarmCenter":
        this.fetchEmaacStatistics$.next();
        break;
      case "careRecipients":
        this.fetchCareRecipientCards$.next();
        break;
    }
  }

  fetchAlertStatistics(): Observable<IAlertStatistics> {
    this.alertsTabDataLoaded = false; // Remember set this to true in the subscription
    return this.api.getAlertStatistics(this.filters);
  }

  fetchEmaacStatistics(): Observable<IEmaacStatistics> {
    this.alarmCenterTabDataLoaded = false;
    return this.api.getAlarmCenterStatistics(this.filters);
  }

  fetchCareRecipientCards(): Observable<CareRecipientStatistics[][]> {
    this.careRecipientCardsLoaded = false;
    return this.api.getCareRecipientStatisticsCards(this.filters);
  }

  /**
   * Check if more cards are available to be added
   * @returns Available status and user card maximum limit
   */
  isCareRecipientCardsAvailable(): Observable<ICareRecipientCardUserLimit> {
    return this.api.checkCareRecipientStatisticsCardUserLimit();
  }

  /**
   * Request for adding note to care recipient statistics card
   * @param cardId ID of card / mongo document ID
   * @param note Note data
   * @returns True if note was added, else false
   */
  addNoteToCareRecipientCard(
    cardId: string,
    note: ICareRecipientCardNote
  ): Observable<boolean> {
    return this.api.addNoteToCareRecipientStatisticsCard(cardId, note);
  }

  /**
   * Request for adding new care recipient statistics card
   * @param config New care recipient statistics card to add
   * @returns True if add success, else false
   */
  createCareRecipientCard(
    config: CareRecipientCard
  ): Observable<boolean> {
    return this.api.createCareRecipientStatisticsCard(config);
  }

  /**
   * Request for updating care recipient statistics card
   * @param config Card update data
   * @returns True if update success, else false
   */
  updateCareRecipientCard(
    config: CareRecipientCard
  ): Observable<boolean> {
    return this.api.updateCareRecipientStatisticsCard(config);
  }

  /**
   * Request for deleting care recipient statistics card
   * @param cardId ID of card / mongo document ID
   * @returns True if delete success, else false
   */
  deleteCareRecipientCard(cardId: string): Observable<boolean> {
    return this.api.deleteCareRecipientStatisticsCard(cardId);
  }

  /**
   * Remove care recipient tab's message after delay
   */
  removeMessageTimer(): void {
    const time = 5000; // 5 seconds

    setTimeout(() => {
      this.careRecipientTabResultMsg = "";
    }, time);
  }
}
