export interface AlarmRouteModel {
  locationId: string;
  locationName: string;
  routes: Route[];
}

export interface Route {
  comment: string;
  alarmIndicationGroupEnabled: boolean;
  alarmIndicationGroups: AlarmIndicationGroup[];
  waitForSmsAcknowledgement: boolean;
  callCenterId: string;
  fallBackType: string;
  fallBackCallCenter: string;
  recipients: Recipient[];
  fallBackRecipients: Recipient[];
  serviceTimeValidity: number;
  serviceTimes: ServiceTime[];
  commonServiceTime?: CommonServiceTime;
  devices: AlarmRouteDevice[];
  id: string;
  name: string;
  callCenterFirst: boolean;
  keepAlarmOpenAfterCallCenter: boolean;
  emaBeforeCallCenter: boolean;
  skipInactiveTeamsForAssistance: boolean;
  defaultRoute: boolean;
  deliveryType: DeliveryType;
  priority: Priority;
  enabled: boolean;
  locationId: string;
  locationName: string;
  callLoopFailType: string;
}

export interface CommonServiceTime {
  startTime: string;
  endTime: string;
}

export interface AlarmIndicationGroup {
  id?: number;
  baseStationName?: string;
  deviceType?: string;
  baseStationId: string;
  buzzerAndLed: boolean;
  buzzerOnly: boolean;
  locationId?: string;
  alarmOnHomeBase: boolean;
  alarmOnRoamingBase: boolean;
}

export interface AlarmRouteDevice {
  deviceId: string;
  deviceType: string;
  deviceName: string;
  activations: Activation[];
  locationId: string;
  locationName?: string;
  connectionTimeLimit?: number;

}

export interface Activation {
  group: number;
  node: number;
}

export interface Recipient {
  id: string;
  type: RecipientType;
  name: string;
  gsmNumber: string;
}

export interface ServiceTime {
  day: string;
  startTime: string;
  endTime: string;
}


export enum RecipientType {
  User = 'USER',
  Group = 'GROUP',
}

export enum DeliveryType {
  CallSMS = 'CALL+SMS',
  CallGroupSMS = 'CALL+GRSMS',
  Email = 'EMAIL',
  Log = 'LOG',
  Smsonly = 'SMSONLY',
  None = 'NONE',
}


export enum Priority {
  Critical = 1,
  High = 2,
  Medium = 3,
  Normal = 4
}

export enum CallCenterDeliveryType {
  UnableToDeliverAlarm,
  CallCenterFirst,
  CallCenterOnly,
  WhenUnableToDeliverEMACallCenterOnly,
  WhenUnableToDeliverEMAAlarm

}


export interface ValidationModel {
  id: string;
  conflicts: Conflict[];
  errors: ValidationError[];

}


export interface Conflict {
  deviceId: string;
  deviceName: string;
  deviceType: string;
  routerName: string;
  routerId: string;
  group: number;
  node: number;
  serviceTimeValidity: number;
  day: string;
  startTime: string;
  endTime: string;
}

export enum ValidationError {
  /// <summary>
  /// Default alarm router cannot have devices or service times configured
  /// </summary>
  DefaultRouterDeviceOrServiceTimesNotEmpty = 'DefaultRouterDeviceOrServiceTimesNotEmpty',
  /// <summary>
  /// Service times needs to have exactly 7 items if daily confs are used, one for each week day
  /// </summary>
  InvalidServiceTimesDayAmount = 'InvalidServiceTimesDayAmount',
  /// <summary>
  /// At least one device needs to be configured alarm routing
  /// </summary>
  DevicesMissing = 'DevicesMissing',
  /// <summary>
  /// Only deliverytypes supporting EMA are valid when EMA fallback is in use (CALL+SMS, CALL+GRSMS, SMSONLY)
  /// </summary>
  DeliveryTypeNotValidForEmaFallback = 'DeliveryTypeNotValidForEmaFallback',
  /// <summary>
  /// Only fallback types supporting EMA are valid when fallback callcenter is in use (CALL+SMS, CALL+GRSMS, SMSONLY)
  /// </summary>
  EmaFallbackTypeNotValidForCallCenter = 'EmaFallbackTypeNotValidForCallCenter',
  /// <summary>
  /// Default alarm routers cannot be added via API
  /// </summary>
  AddingDefaultRouterNotAllowed = 'AddingDefaultRouterNotAllowed',
  /// <summary>
  /// Same alarm router name only allowed once per customer
  /// </summary>
  NameAlreadyInUse = 'NameAlreadyInUse',
  /// <summary>
  /// At least 1 recipient needs to be configured for the routing (unless the delivery type is LOG)
  /// </summary>
  RecipientsMissing = 'RecipientsMissing',
  /// <summary>
  /// At least one activation is required per device
  /// </summary>
  ActivationsForDeviceMissing = 'ActivationsForDeviceMissing',
  /// <summary>
  /// Conflicting alarm routers found
  /// </summary>
  ConlictingRoutersFound = 'ConlictingRoutersFound'
}


export enum ServiceTimeValidity {
  Never = 0,
  Always = 10,
  Daily = 1,
}

