// Form model for adding new company
export class AddCompanyForm {
  companyName: string;
  address: string;
  postCode: string;
  postOffice: string;
  country: string = "";
  timezone: any;
  companyGroupId: string = "";
  server: string = "";
  contactPhone: string;
  mainUserGsm: string;
  deliveryMethod: string = "EMAIL";
  mainUserAccount: string;
  mainUserFirstName: string;
  mainUserLastName: string;
  gsmLang: string = "EN";
  password: string;
  passwordVerify: string;
  email: string;
}

// Company model
export class Company {
  companyId: string;
  companyName: string;
  address: string;
  postCode: string;
  postOffice: string;
  country: string;
  timeZone: string;
  mainUser: string;
  mainUserGsm: string;
  langId: string;
  contactPhone: string;
  createDate: string;
  deliveryMethod: string;
  server: string;
}

// Model used for listing companies in view
export interface ICompanyList {
  companyId: string;
  companyGroupId?: string;
  companyName: string;
  server: string;
}

export interface ICompanyGroup {
  name: string;
  companyGroupId: string;
  parentCompanyGroupId: string;
  organizationNumber: string;
  address: string;
  postOffice: string;
  postCode: string;
  contactPhone: string;
  timezone: string;
  langId: string;
  country: string;
  deviceBindingTimeVisible?: boolean;
  deviceBindingTime?: number;
  deviceBindingTimeUnit?: DeviceBindingTimeUnit;
  server?: string;
  populateStaffEnabled?: boolean;
  companies: ICompanyGroupCompany[];
}

export interface ICompanyGroupCompany {
  address: string;
  company: string;
  companyId: string;
  companyGroupId?: string;
  confirmDelete?: boolean;
  populateStaff?: boolean;
  addToAllReceiverGroups?: boolean;
}

export interface IUpdateCompanyGroupBindingTimeForm {
  companyGroupId: string;
  server: string;
  deviceBindingTime: number;
  deviceBindingTimeUnit: DeviceBindingTimeUnit;
  deviceBindingTimeVisible: boolean;
}

export type DeviceBindingTimeUnit = "Days" | "Months" | "Years";


