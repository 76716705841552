import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Service for the desktop notifications
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private translate: TranslateService) {
    // Check if browser supports desktop notifications
    if (window.Notification) {
      if (Notification.permission !== "granted") {
        // Ask permission from user if not already granted
        Notification.requestPermission();
      }
    }
    else {
      // REMEMBER TO ADD TRANSLATIONS
      alert(this.translate.instant("NOTIFICATIONS_NOT_SUPPORTED"));
    }
  }

  translations = {
    NEW_ALERT: this.translate.instant("NEW_ALERT"),
    NEW_VISITS: this.translate.instant("NEW_VISITS"),
    UNANSWERED_ALERT: this.translate.instant("UNANSWERED_ALERT"),
    MINUTES_REMAINING_FOR: this.translate.instant("MINUTES_REMAINING_FOR"),
    VISITS: this.translate.instant("VISITS"),
  }

  /**
   * Notification when a new alert comes in from alerting device
   * @param {string} deviceName Name of the alerting device
   */
  notifyNewAlert(deviceName: string): void {
    if (this.isNotificationPermitted()) {
      new Notification("", {
        body: this.translations.NEW_ALERT + " - " + deviceName,
        icon: "/assets/img/desktop_notifications/bell-solid.svg"
      });
      new Audio("/assets/sounds/1.mp3").play();
    }
  }

  /**
   * Notification when x number of new visits come in
   * @param {number} visitCount Number of visits
   */
  notifyNewVisits(visitCount: number): void {
    if (this.isNotificationPermitted()) {
      new Notification("", {
        body: visitCount + " " + this.translations.NEW_VISITS,
        icon: "/assets/img/desktop_notifications/camera-cctv-solid.svg"
      });
      new Audio("/assets/sounds/1.mp3").play();
    }
  }

  /**
   * Notification when alert has not been answered in 2 minutes
   */
  notifyUnansweredAlert(): void {
    if (this.isNotificationPermitted()) {
      new Notification("", {
        body: this.translations.UNANSWERED_ALERT,
        icon: "/assets/img/desktop_notifications/triangle-exclamation.svg"
      });
      new Audio("/assets/sounds/1.mp3").play();
    }
  }

  /**
   * Notification when x minutes remain to perform x number of visits
   * @param {number} visitCount Number of visits
   * @param {number} mins Remaining minutes
   */
  notifyRemainingTimeForVisits(visitCount: number, mins: number = 5): void {
    if (this.isNotificationPermitted()) {
      new Notification("", {
        body: `${mins} ${this.translations.MINUTES_REMAINING_FOR} ${visitCount} ${this.translations.VISITS}`,
        icon: "/assets/img/desktop_notifications/triangle-exclamation.svg"
      });
      new Audio("/assets/sounds/1.mp3").play();
    }
  }

  /**
   * Check notification permission
   * @returns True if permitted, else false
   */
  isNotificationPermitted(): boolean {
    if (window.Notification) {
      if (Notification.permission === "granted") {
        return true;
      }
    }
    return false;
  }
}
