export class LockBeaconModel {
    deviceId: string;
    name: string;
    deviceType: string;
    alertWhenButtonInLockArea: boolean;
    openDoorForUnknownButton: boolean;
    closeDoorForUnknownButton: boolean;
    allowedDevices: LockBeaconDevice[];
    deniedDevices: LockBeaconDevice[];
    lostDevicesUpdated: boolean;
}

export class LockBeaconDevice {
    id: string;
    name: string;
    deviceType: string;
    lostDevice: boolean;
    validityTimes: LockBeaconValidityTimes[];
    editMode: boolean; //UI ONLY
}

export class LockBeaconValidityTimes {
    weekDaysFrom: string = "00:00";
    weekDaysTo: string = "23:59";
    saturdaysFrom: string = "00:00";
    saturdaysTo: string = "23:59";
    sundaysFrom: string = "00:00";
    sundaysTo: string = "23:59";
}