import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { IResponseTimeStatistics } from "app/models/statistics.model";
import { SecondsToReadableFormatPipe } from "app/pipes/pipes";
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from "@angular/core";

@Component({
  selector: "response-time-statistics",
  templateUrl: "./response-time-statistics.component.html",
  styleUrls: ["./response-time-statistics.component.css"],
  standalone: true,
  imports: [FormsModule, TranslateModule, SecondsToReadableFormatPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponseTimeStatisticsComponent implements OnInit {
  constructor() {}

  @Input() resTimeStats: IResponseTimeStatistics;

  selectedOption: "average" | "median" = "average";

  ngOnInit(): void {}
}
