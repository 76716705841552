import { Chart } from "chart.js";

/**
 * Get or create custom tooltip for the chart.js chart
 */
const getOrCreateCustomTooltip = (chart: Chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(7, 24, 77, 0.85)";
    tooltipEl.style.borderRadius = "8px";
    tooltipEl.style.color = "#FFFF";
    tooltipEl.style.opacity = "1";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    //tooltipEl.style.transition = "all .1s ease";

    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

/**
 * External tooltip handler for the EMAAC-alerts pie chart
 */
export const emaacAlertsCustomTooltipHandler = (context: any) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateCustomTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = "0";
    return;
  }

  // Set tooltip text
  if (tooltip.body) {
    // Get pie chart's currently pointed data group's label and split the words
    const currentDataGroupLabel: string = tooltip.body[0].lines[0];
    const labelWords: string[] = currentDataGroupLabel.split(" ");

    // Pick the current alert count from last word of the array
    const alertCountString: string = labelWords[labelWords.length - 1];

    // Remove any potential delimiter characters (which are known to cause bugs with alert counts of 1000 or more) and parse the number to an integer
    const currentAlertCount: number = parseInt(alertCountString.replace(/\D/g, ''));

    // Sum chart's all alerts
    const alerts: number[] = context.chart.data.datasets[0].data;
    const totalAlerts: number = ((numbers: number[]): number => {
      let sum = 0;
      for (let i = 0; i < numbers.length; i++) {
        sum += numbers[i];
      }
      return sum;
    })(alerts);

    // Create element for the text and calculate the percentage of current alerts out of total alerts
    const span = document.createElement("span");
    span.textContent = `${((currentAlertCount / totalAlerts) * 100).toFixed(
      1
    )} %`;

    // Remove previous texts
    while (tooltipEl.firstChild) {
      tooltipEl.firstChild.remove();
    }

    // Add current text
    tooltipEl.appendChild(span);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for custom tooltip
  tooltipEl.style.opacity = "1";
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.padding = "10px 15px 10px 15px";
};
