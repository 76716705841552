<div class="card">
    <h4 class="normalFontWeight">
        {{ "ALERTS_WITH_FIRST_LETTER_UPPERCASE" | translate }}
    </h4>

    <h2 id="alertCounter">{{ emaacAlertStats.totalAlerts }}</h2>

    <div id="chartContainer">
        <canvas id="emaacAlertsChart" width="280" height="280"></canvas>
    </div>

    <div class="d-flex" style="height: 27px">
        <div
            class="square mt-1"
            style="background-color: rgb(255, 118, 74)"
        ></div>
        <p class="legendInfo">
            {{ labels[0] + ": " + emaacAlertStats.emaAlerts }}
        </p>
    </div>

    <div class="d-flex" style="height: 20px">
        <div
            class="square mt-1"
            style="background-color: rgb(44, 63, 104)"
        ></div>
        <p class="legendInfo">
            {{ labels[1] + ": " + emaacAlertStats.finishedAlerts }}
        </p>
    </div>
</div>
