import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor() { }

  getActiveConnectionInfo(data: SignalData[], connectionType: number): string {
    let level = this.getLastSignalQuality(data)
    // 0 = Modem/GSM, 1 = Ethernet/LAN, 2 = Wifi
    let media = "";
      switch (connectionType) {
        case 0:
          media = "Modem/GSM"
          break;
        case 1:
          media = "Ethernet/LAN"
          break;
        case 2:
          media = "Wifi"
          break;
      }

    return media
    // let signalLevelInfo = level ? `${this.translated_SIGNAL_LEVEL}: ${level}` : `${this.translated_NO_SIGNAL_STRENGTH}` // "Signal strength not provided."
    // let tooltipMsg = connectionType == 0 ? `${media}, ${signalLevelInfo}` : media
    // return tooltipMsg
  }

  getColorClass(data: SignalData[]): string {
    let number = this.getLastSignalQuality(data)
    return this.getSignalQualityClass(number)
  }

  getSignalQualityClass(value: number): string {
    if (isNaN(value)) {
        return 'text-dark'; // Handle NaN
    }

    let result: string = 'text-danger'; // Default value

    if (value > -75 && value !== 0) {
        result = 'text-success';
    } else if (value <= -75 && value > -95) {
        result = 'text-warning';
    } else if (value <= -95 || value === 0) {
        result = 'text-danger';
    }

    return result;
  }

  /**
   * Function to find a signalLevel of the last connection in an array of SignalData objects.
   * @param data - Array of SignalData objects
   * @returns The signalLevel value of the latest connection
   */
  getLastSignalQuality(data: SignalData[]): number {
    if (!data || data.length === 0) {
      // handles the case when the array is empty
      return NaN;
    }

      const latestSignalData = data.reduce((latest, current) => {
        const currentLogTime = new Date(current.logTime);
        const latestLogTime = latest ? new Date(latest.logTime) : undefined;

        if (!latestLogTime || currentLogTime > latestLogTime) {
            return current;
        }

        return latest;
      });

    return latestSignalData.signalLevel;
  }

  featureEnabled(feature: string): boolean {
    let features: [String] = JSON.parse(localStorage.getItem("features"));
    if (features) {
      return features.includes(feature);
    }
    return false;
  }

  isLock(deviceType: string): boolean {
    // '32-9'  // Danalock
    // '32-10' // Masterlock
    // '32-11' // Salto lock
    // '32-13' // Virtual lock
    const lockTypes = ['32-9', '32-10', '32-11', '32-13'];
    return lockTypes.includes(deviceType);
  }

  getBatteryClass(value: any): string {
    switch (value) {
      case 0:
        return 'fa-battery-slash text-danger';
      case 1:
        return 'fa-battery-quarter text-warning';
      case 2:
        return 'fa-battery-three-quarters text-success';
      case 3:
        return 'fa-battery-full text-success';
      default:
        break;
    }
  }

  getBatteryType(value: any): string {
    switch (value) {
      case 1:
        return '3V';
      case 2:
        return '9V';
      case 3:
        return '';
      case 4:
        return '6V';
      default:
        break;
    }
  }
}

  // Interface for the array of objects
  interface SignalData {
    signalLevel: number;
    operator: string;
    mccMnc: string;
    logTime: string;
    networkMode: string;
    networkSurvey: boolean;
  }
