import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { ApiService } from "./api.service";
import { UiLang } from "./helper.service";
import {
  IAlarmReason,
  IAlarmReasonCategory,
  IDeleteAlarmReason,
  IDeleteAlarmReasonCategory,
  IEarlierCheckedAlarmsGroup,
  IJustCheckedAlarmsGroup,
  IAlarmReasonTranslations,
  ICategoryPlusAlarm,
  IStoredAlarmReasonsByAlert,
  IReasonsGroupedByCategory,
} from "app/models/alarm-reasons.model";
import { map } from "rxjs/operators";
import { OptionGroup, Option } from "app/components/category-multiselect-button/category-multiselect-button-models";

@Injectable({
  providedIn: "root",
})
export class AlarmService {
  constructor(private api: ApiService) { }

  // --------------------- for fetching and emitting all possible alarm reasons ------------------------
  private allCategoriesWithAlarmReasons = new BehaviorSubject<IAlarmReasonCategory[]>(
    []
  );
  /**
   * this observable is subscribed for in
   * - app-display-alarm-reasons component
   * - alarm-reason-checklist component
   * - app-alarm-reasons component
   */
  alarmReasonsSource$ = this.allCategoriesWithAlarmReasons.asObservable();
  // --------------------------------------------------------------------------------------------------------

  private sortingIndex = new Subject<number>();
  sortingIndex$ = this.sortingIndex.asObservable();

  // ---------------------- for storing and emitting checked alarm reasons ---------------------------------
  private allStoredAlarmReasons = new BehaviorSubject<
    IEarlierCheckedAlarmsGroup[]
  >([]);
  /**
   * this observable is subscribed for in
   * - mark-alarm-reason component
   * - display-alarm-reason component
   *
   */
  allStoredAlarmReasons$ = this.allStoredAlarmReasons.asObservable();

  doAlertHaveCheckedAlarmReasons(alertID: string): Observable<boolean> {
    return this.allStoredAlarmReasons$.pipe(
      map(checkedGroups => {
        const targetAlert = checkedGroups.find(group => group.alertId === alertID);

        if (targetAlert) {
          // Transform the array of reasons into a boolean
          return targetAlert.reasons?.length > 0 || false;
        } else {
          // If alertID is not found, return false or handle as needed
          return false;
        }
      })
    );
  }

  //--------------------------------------------------------------------------------------------------------


  private alarmsWithEnabledBtns = new BehaviorSubject<string[]>([]);
  alarmsWithEnabledBtns$ = this.alarmsWithEnabledBtns.asObservable();
  checkedTasks = [];

  private checkedTasksLength = new Subject<number>();
  checkedTasksLength$ = this.checkedTasksLength.asObservable();

  private storeAlarmReasonsLocally(): void {
    this.api.getAlarmReasons("ALL").subscribe((res: string) => {
      localStorage.setItem("alarmReasons", res);
    });
  }

  // after a note has been attached to a task
  // the task loose all alarm reasons which were picked up by the checkbox form
  // to rebuild the form using previously temporarily saved reasons they have to be stored
  private updAlarmAfterAddingNote: IStoredAlarmReasonsByAlert[] = [];
  addReserveAlarm(alarm: IStoredAlarmReasonsByAlert) {
    this.updAlarmAfterAddingNote = [
      ...this.updAlarmAfterAddingNote.filter(
        (alreadyAdded) => alreadyAdded.alertId !== alarm.alertId
      ),
    ];
    this.updAlarmAfterAddingNote.push(alarm);
    // console.log("reserve", this.updAlarmAfterAddingNote)
  }

  getReservedAlarm(id: string) {
    return this.updAlarmAfterAddingNote.find(
      (alreadyAdded) => alreadyAdded.alertId === id
    );
  }

  deleteReservedAlarm(id: string) {
    this.updAlarmAfterAddingNote = [
      ...this.updAlarmAfterAddingNote.filter(
        (alreadyAdded) => alreadyAdded.alertId !== id
      ),
    ];
  }

  // this function is used only to test that tasks are properly removed after sending task to API
  getAllReservedAlarms() {
    return this.updAlarmAfterAddingNote;
  }
  //---------------- store pairs alarmReason-Category by alert localy without sending to API --------------------------------
  //------------------------------------------------------------------------------------------------------------

  private alarmReasonsStorage: IStoredAlarmReasonsByAlert[] = [];
  /**
   * Adds new alarm reasons to the stored list for a specific alert.
   *
   * @param alertId {string} - The unique identifier for the alert.
   * @param alarmReasonsToAdd {ICategoryPlusAlarm[]} - The alarm reasons to add to the list.
   */
  storeAlarmReasons(alertId: string, alarmReasonsToAdd: ICategoryPlusAlarm[]): void {
    // First, remove any existing alarm reasons for the given alert.
    this.removeAlarmReasonsFromStorage(alertId);

    // Then, add the new alarm reasons to the stored list.
    this.alarmReasonsStorage.push({
      alertId: alertId,
      reasons: alarmReasonsToAdd,
    });
  }

  /**
   * Removes alarm reasons associated with a specific alert from the storage.
   *
   * @param alertId {string} - The unique identifier for the alert from which alarm reasons should be removed.
   */
  removeAlarmReasonsFromStorage(alertId: string): void {
    this.alarmReasonsStorage = [
      ...this.alarmReasonsStorage.filter((alert) => alert.alertId !== alertId),
    ];
  }

  /**
   * Retrieves alarm reasons for a specific alert.
   *
   * @param alertId {string} - The unique identifier for the alert.
   * @returns {ICategoryPlusAlarm[]} - An array of alarm reasons associated with the alert.
   */
  getAlarmReasonsForAlert(alertId: string): ICategoryPlusAlarm[] {
    return this.alarmReasonsStorage.find((item) => item.alertId === alertId)?.reasons || [];
  }

  // -------------------------------------------------------------------------------------------------------------

  addToEnabledAlarms(id: string) {
    if (!this.checkedTasks.includes(id)) {
      this.checkedTasks.push(id);
      this.alarmsWithEnabledBtns.next(this.checkedTasks);
      this.checkedTasksLength.next(this.checkedTasks.length);
    }
  }

  removeFromEnabledAlarms(id: string) {
    this.checkedTasks = this.checkedTasks.filter((item) => item !== id);
    this.alarmsWithEnabledBtns.next(this.checkedTasks);
    this.checkedTasksLength.next(this.checkedTasks.length);
  }

  getOneArrayWithId(array: IJustCheckedAlarmsGroup[]) {
    let alertID = array[0]?.alertId;
    const inOneArray = array.map((item) => {
      return {
        categoryName: item.categoryName,
        alarmNames: item.alarmNames,
      };
    });
    return {
      alertId: alertID,
      reasons: inOneArray,
    };
  }

  addOrRewriteItem(savedArray, updatedList) {
    let updatedArray = [];
    let needToPush = true;

    let updatedAlertId = updatedList.alertId;
    let checkedReasons = [];

    updatedList["reasons"].forEach((category) => {
      if (category.alarmNames.length > 0) {
        checkedReasons.push(category);
      }
    });

    let listWithoutEmptyReasons = {
      alertId: updatedAlertId,
      reasons: checkedReasons,
    };

    // make list of categories shorter
    if (checkedReasons.length === 0) {
      // delete category from the list if user clicked all alarm reasons in it as unchecked
      return savedArray.filter((item) => item.alertId !== updatedAlertId);

      // modify list of categories
    } else {
      updatedArray = savedArray.map((member) => {
        // find category and rewrite its alarm reasons
        if (member.alertId === updatedAlertId) {
          needToPush = false;
          return (member = listWithoutEmptyReasons);
          // do not touch other categories from the list
        } else {
          return member;
        }
      });

      // if alarm reason(s) belong(s) to a category, which is not in the list, add it to the list
      needToPush ? updatedArray.push(listWithoutEmptyReasons) : null;
      return updatedArray;
    }
  }

  saveReasons(
    existingArray: IEarlierCheckedAlarmsGroup[],
    justChecked: IJustCheckedAlarmsGroup[]
  ): IEarlierCheckedAlarmsGroup[] {
    let withExtractedId = this.getOneArrayWithId(justChecked);
    let rewritten = this.addOrRewriteItem(existingArray, withExtractedId);

    return rewritten;
  }

  private allCheckedAlarms: IEarlierCheckedAlarmsGroup[] = [];
  /**
   * Stores checked alarms in the application.
   *
   * @param {IJustCheckedAlarmsGroup[]} checkedReasons - An array of groups containing checked alarms.
   *
   * @returns {void}
   */
  storeCheckedAlarms(checkedReasons: IJustCheckedAlarmsGroup[]): void {
    this.allCheckedAlarms = this.saveReasons(
      this.allCheckedAlarms,
      checkedReasons
    );
    this.allStoredAlarmReasons.next(this.allCheckedAlarms);
  }

  deleteAlarmReasonsFromAll(alertId) {
    let filteredList = this.allCheckedAlarms.filter(
      (alarm) => alarm.alertId !== alertId
    );
    this.allStoredAlarmReasons.next(filteredList);
  }

  /**
   * Retrieves alarm reasons from the API and emits them.
   *
   * This function makes an API request to fetch alarm reasons and updates the state
   * by notifying subscribers of the `allCategoriesWithAlarmReasons` observable in
   *  - app-display-alarm-reasons component
   *  - alarm-reason-checklist component
   *  - app-alarm-reasons component
   *
   * @returns {void}
   */
  getAlarmReasons(): void {
    this.api
      .getAlarmReasons("ALL")
      // .pipe(shareReplay(1))
      .subscribe((res: string) => {
        this.allCategoriesWithAlarmReasons.next(JSON.parse(res));
      });
  }

  setSortingIndex(index: number) {
    this.sortingIndex.next(index);
  }

  sortAlphabetically(
    alarmsArr: IAlarmReasonCategory[],
    categoryName: string,
    alarmName: string
  ): IAlarmReasonCategory[] {
    let arrayToSort = [...alarmsArr];
    arrayToSort.sort((a, b) => (a[categoryName] > b[categoryName] ? 1 : -1));
    arrayToSort.forEach((category) => {
      category.alarmReasons.sort((a, b) =>
        a[alarmName] > b[alarmName] ? 1 : -1
      );
    });
    return arrayToSort;
  }

  getAlarmsTranslated(language: string): string[] {
    let categoryNameTranslated: string;
    let alarmNameTranslated: string;
    switch (language) {
      case "en":
        categoryNameTranslated = "alarmReasonCategory_EN";
        alarmNameTranslated = "alarmReason_EN";
        break;
      case "fi":
        categoryNameTranslated = "alarmReasonCategory_FI";
        alarmNameTranslated = "alarmReason_FI";
        break;
      case "sv":
        categoryNameTranslated = "alarmReasonCategory_SE";
        alarmNameTranslated = "alarmReason_SE";
        break;
      case "ee":
        categoryNameTranslated = "alarmReasonCategory_EE";
        alarmNameTranslated = "alarmReason_EE";
        break;
      default:
        categoryNameTranslated = "alarmReasonCategory_EN";
        alarmNameTranslated = "alarmReason_EN";
    }
    return [categoryNameTranslated, alarmNameTranslated];
  }

  getAlarmReasonTranslations(
    language: UiLang = "en"
  ): Observable<IAlarmReasonTranslations> {
    return new Observable((observer) => {
      let translations: IAlarmReasonTranslations = JSON.parse(
        localStorage.getItem("alarmReasonTranslations")
      );
      // Fetch and parse alarm reasons and store the translations if not found
      if (!translations) {
        this.api.getAlarmReasons("ALL").subscribe((res: string) => {
          translations = this.parseAlarmReasonTranslations(
            JSON.parse(res),
            language
          );
          localStorage.setItem(
            "alarmReasonTranslations",
            JSON.stringify(translations)
          );
          observer.next(translations);
          observer.complete();
        });
      } else {
        observer.next(translations);
        observer.complete();
      }
    });
  }

  private parseAlarmReasonTranslations(alarmCategories: IAlarmReasonCategory[], language: UiLang): IAlarmReasonTranslations {
    const translations: IAlarmReasonTranslations = {
      alarmCategory: [],
      alarmReason: [],
    };

    // Loop through all categories and alarm reasons in the category to parse translations
    for (const category of alarmCategories) {
      translations.alarmCategory.push({
        key: category.dictionaryEntryKey,
        categoryId: category.alarmReasonCategoryID,
        translation: this.translateAlarmCategory(category, language),
      });

      for (const alarmReason of category.alarmReasons) {
        translations.alarmReason.push({
          key: alarmReason.dictionaryEntryKey,
          categoryId: alarmReason.alarmReasonCategoryID,
          translation: this.translateAlarmReason(alarmReason, language),
        });
      }
    }
    return translations;
  }

  translateAlarmCategory(
    category: IAlarmReasonCategory,
    language: UiLang
  ): string {
    let translation = "";
    switch (language) {
      case "en":
        translation = category.alarmReasonCategory_EN;
        break;
      case "fi":
        translation = category.alarmReasonCategory_FI;
        break;
      case "sv":
        translation = category.alarmReasonCategory_SE;
        break;
      case "ee":
        translation = category.alarmReasonCategory_EE;
        break;
      default:
        translation = category.alarmReasonCategory_EN;
    }
    return translation;
  }

  translateAlarmReason(alarmReason: IAlarmReason, language: UiLang): string {
    let translation = "";
    switch (language) {
      case "en":
        translation = alarmReason.alarmReason_EN;
        break;
      case "fi":
        translation = alarmReason.alarmReason_FI;
        break;
      case "sv":
        translation = alarmReason.alarmReason_SE;
        break;
      case "ee":
        translation = alarmReason.alarmReason_EE;
        break;
      default:
        translation = alarmReason.alarmReason_EN;
    }
    return translation;
  }

  parseAlarmReasonFilterOptions(alarmReasonTranslation: IAlarmReasonTranslations): OptionGroup[] {
    const options: OptionGroup[] = [];
    const alarmReasons = alarmReasonTranslation.alarmReason;

    for (const category of alarmReasonTranslation.alarmCategory) {
      options.push(
        new OptionGroup(
          category.translation,
          alarmReasons
            .filter((reason) => reason.categoryId === category.categoryId)
            .map((reason) => new Option({
              alarmReason: reason.key,
              category: category.key,
            },
              reason.translation)),
        )
      );
    }
    return options;
  }

  deleteAlarmReason(reason: IDeleteAlarmReason) {
    this.api.deleteAlarmReason(reason).subscribe();
  }

  deleteAlarmReasonCategory(category: IDeleteAlarmReasonCategory) {
    this.api.deleteAlarmReasonCategory(category).subscribe();
  }

  saveAlarmReasons(data: IAlarmReasonCategory[]) {
    return this.api.saveAlarmReasons(data);
  }
}
