<div class="row">
    <div class="col-sm-6">
        <div class="card">
            <div class="card-header-white header-underline">
                <div class="white-header-text">
                    <span *ngIf="!fwFormEditMode">
                        <h6>{{'FIRMWARE_UPLOAD' |translate}}</h6>
                    </span>
                    <span *ngIf="fwFormEditMode">
                        <h6>{{'FIRMWARE_EDIT' |translate}}</h6>
                    </span>
                </div>
            </div>
            <div class="card-body">
                <form [formGroup]="uploadFirmwareForm" (ngSubmit)="formSubmitSubject.next()">
                    <div class="form-group">
                        <label>{{'DEVICE_TYPE' |translate}}</label>

                        <mat-form-field class="selector-full-width">
                            <mat-select formControlName="deviceType" (selectionChange)="deviceTypeSelectionChange()">
                                <mat-option value="31-13">TP-202</mat-option>
                                <mat-option value="31-14">TP-202G</mat-option>
                                <mat-option value="31-15">TP-202G2</mat-option>
                                <mat-option value="32-3">TP-150G</mat-option>
                                <mat-option value="32-8">PL-151SA</mat-option>
                                <mat-option value="05-7">PL-100G2</mat-option>
                                <mat-option value="05-8">PL-100W2</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div class="form-group">
                        <label>HwVersion</label>
                        <input type="number" class="form-control" placeholder="HwVersion" formControlName="hwVersion"
                            name="hwVersion">
                    </div>
                    <div class="form-group">
                        <label>SwVersion</label>
                        <input type="number" class="form-control" placeholder="SwVersion" formControlName="swVersion"
                            name="swVersion">
                    </div>
                    <div class="form-group">
                        <label>SwType</label>

                        <mat-form-field class="selector-full-width">
                            <mat-select formControlName="swType">
                                <ng-container
                                    *ngIf="uploadFirmwareForm.value.deviceType === '31-13' || uploadFirmwareForm.value.deviceType === '31-14' || uploadFirmwareForm.value.deviceType === '31-15'">
                                    <mat-option value="1">Application</mat-option>
                                    <mat-option value="2">Bluetooth</mat-option>
                                    <mat-option value="3">Wifi</mat-option>
                                </ng-container>
                                <ng-container
                                    *ngIf="uploadFirmwareForm.value.deviceType !== '31-13' && uploadFirmwareForm.value.deviceType !== '31-14' || uploadFirmwareForm.value.deviceType !== '31-15'">
                                    <mat-option value="4">Terminal</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div class="form-group">
                        <label>{{'DESCRIPTION' |translate}}</label>
                        <input type="text" class="form-control" placeholder="{{'DESCRIPTION' |translate}}"
                            formControlName="description" name="description">
                    </div>
                    <div class="form-group">
                        <label>{{'CHANGELOG' |translate}}</label>
                        <textarea class="form-control" formControlName="changeLog" name="changeLog">
                            </textarea>
                    </div>
                    <div class="form-check">
                        <input id="fwDisabled" class="form-check-input" type="checkbox" formControlName="disabled"
                            name="disabled">
                        <label for="fwDisabled" class="form-check-label">{{'DISABLED' |translate}}</label>
                    </div>
                    <div class="form-check">
                        <input id="fwProduction" class="form-check-input" type="checkbox" formControlName="productionSw"
                            name="productionSw">
                        <label for="fwProduction" class="form-check-label">{{'PRODUCTION' |translate }}</label>
                    </div>
                    <div class="form-check mb-2">
                        <input id="fwRecommended" class="form-check-input" type="checkbox"
                            formControlName="recommendedSw" name="recommendedSw">
                        <label for="fwRecommended" class="form-check-label">{{'RECOMMENDED' |translate}}</label>
                    </div>
                    <div class="form-group">
                        <div *ngIf="!fwFormEditMode">
                            <label>{{'FILE' |translate}}</label>
                            <input type="file" class="form-control-file" (change)="fileChange($event)"
                                formControlName="fwFile" name="fw" accept=".hex, .bin, .txt">
                        </div>
                        <div *ngIf="fwFormEditMode">
                            <span class="btn btn-outline-danger" (click)="deleteFw()"><span
                                    class="fa-light fa-trash-alt"></span> {{'FIRMWARE_DELETE' |translate}}</span>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="!fwFormEditMode">
                        <button type="submit" [disabled]="!uploadFirmwareForm.valid"
                            class="btn btn-outline-primary gray-bg mr-2"><span class="fa-light fa-upload"></span>
                            {{'UPLOAD'
                            |translate}}</button>
                        <button type="button" class="btn btn-outline-danger" (click)="resetFwForm();"
                            name="resetAddNew"><span class="fa-light fa-xmark"></span> {{'CANCEL' |translate}}</button>
                    </div>
                    <div class="form-group" *ngIf="fwFormEditMode">
                        <button type="submit" [disabled]="!uploadFirmwareForm.valid"
                            class="btn btn-outline-primary gray-bg mr-2"><span class="fa-light fa-floppy-disk"></span>
                            {{'SAVE'
                            | translate}}</button>
                        <button type="button" class="btn btn-outline-danger" (click)="resetFwForm();"
                            name="resetEditMode"><span class="fa-light fa-xmark"></span> {{'CANCEL'
                            |translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-6">
        <div class="card">
            <div class="card-header-white header-underline">
                <h6 class="white-header-text">{{'FIRMWARES' |translate}}</h6>
            </div>
            <div class="card-body">
                <div class="firmwareList">
                    <div class="fwLegend">
                        <span class="fa-light fa-fw fa-star"></span> = {{'PRODUCTION' |translate}}
                    </div>
                    <div *ngFor="let sw of swVersions |slice:0:swShowCount">
                        <div class="float-left productionFw">
                            <span class="fa-light fa-fw fa-star" *ngIf="sw.productionSw"></span>
                        </div>
                        <div class="firmwareListItem">
                            <span title="{{sw.description}}"
                                [ngClass]="{'disabledSw': sw.disabled, 'recommendedSw': sw.recommendedSw}"
                                class="cursorPointer" (click)="selectSw(sw)">{{getDevType(sw.deviceType)}} -
                                REL{{sw.swVersion}} - ID {{sw.firmwareId}} ({{sw.description}})</span>
                        </div>
                    </div>
                    <div>
                        <span class="showAll" (click)="swShowCount = swShowCount.length"
                            *ngIf="swShowCount == 10">{{'SHOW_ALL' |translate}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
